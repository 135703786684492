


































import { Mapping, MdfType } from '@/common/xmpUtils';
import store, { ActionsTypes, GettersTypes } from '@/store';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MultiplechoiceComponent extends Vue {
  @Prop() readonly mdf!: MdfType;
  @Prop() readonly itemLabel!: string;
  @Prop() readonly disableFlag!: boolean;

  get mdfId() {
    return `#${this.mdf.fieldId}`;
  }

  get dataObject() {
    return store.getters[GettersTypes.GET_METADATA_FIELD_VALUES];
  }

  set dataObject(value: any) {
    store.dispatch(ActionsTypes.SET_METADATA_FIELDS, value);
  }

  get metadataXmpFlag() {
    return store.getters[GettersTypes.GET_METADATA_FROM_XMP_FLAG];
  }

  set metadataXmpFlag(value: boolean) {
    store.dispatch(ActionsTypes.SET_METADATA_XMP_FLAG, value);
  }

  selectedValues: Array<string> = [];

  async mounted() {
    if (this.dataObject[this.mdf.fieldId]) {
      let items = this.dataObject[this.mdf.fieldId];
      if (this.mdf.freeform && items) {
        this.selectedValues = [...items] as string[];
        return;
      } else if (!this.mdf.freeform && items && this.mdf.fieldId === 'iptcCountry') {
        this.dataObject[this.mdf.fieldId] = this.getConvertedToLabel(items);
        items = this.dataObject[this.mdf.fieldId];
      }
      if (typeof items === 'string') this.selectedValues.push(items);
      if (this.dataObject[this.mdf.fieldId] && !this.metadataXmpFlag) this.selectedValues = this.getLabelsForHistory(items);
      else this.selectedValues = [...items];
    }

    if (this.dataObject && this.selectedValues) this.dataObject[this.mdf.fieldId] = this.getValues(this.selectedValues);
    this.mappedData();
  }

  get valueList() {
    const valueList: Array<string> = [];
    if (this.mdf.alternatives)
      for (const item of this.mdf.alternatives) {
        valueList.push(item.value);
      }
    return valueList;
  }

  getLabelsForHistory(values: string[]) {
    const data: string[] = [];
    for (const value of values) {
      for (const item of this.mdf.alternatives) {
        if (item.value === value) {
          data.push(item.label);
          break;
        }
      }
    }
    return data;
  }

  getValues(labels: string[]): string[] {
    if (this.mdf.freeform) return labels;

    const values: string[] = [];
    if (this.mdf.alternatives) {
      for (const label of labels) {
        for (const item of this.mdf.alternatives) {
          if (item.label === label) {
            values.push(item.value);
            break;
          }
        }
      }
    } else return labels;
    return values;
  }

  get multipleChoiceLabel() {
    if (this.mdf.required) return `${this.itemLabel}*`;
    return this.itemLabel;
  }

  get labelList() {
    const labelList: Array<string> = [];
    if (this.mdf.alternatives)
      for (const item of this.mdf.alternatives) {
        labelList.push(item.label);
      }
    return labelList;
  }

  getConvertedToLabel(values: string[]) {
    const results: string[] = [];
    if (this.mdf.alternatives) {
      for (const item of this.mdf.alternatives) {
        if (values.includes(item.value)) results.push(item.label);
      }
    }
    return results;
  }

  multiplechoiceEvent() {
    this.mappedData();
  }

  private mappedData() {
    const data: Mapping = {
      value: this.getValues(this.selectedValues),
      id: this.mdf.fieldId,
    };
    this.$emit('multipleChoiceData', data);
  }
}
