









import { compatibleTenantGroupsInfo, createTenantGroup, getTenantGroupList } from '@/common/tenantGroups';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import store, { ActionsTypes, GettersTypes } from '@/store';
import { MultiTenantGroupsInfo, TenantGroupsInfo } from '@/utils/Types/TenantGroupsInfoType';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class AddGroup extends Vue {
  @Prop({ default: null }) public tenantId!: string | null;
  @Prop({ default: false }) public applyClicked!: boolean;

  newGroupName = '';
  newGroupDescription = '';
  tenantGroups: Array<string> = [];
  loading = false;

  getTenantGroups() {
    return this.tenantGroups;
  }

  groupNameRules = [
    (groupName: string) => !!groupName || 'Group name is required',
    (groupName: string) => !groupName || /^[a-z-]+$/.test(groupName) || 'Group name has to be smaller cased',
    (groupName: string) => !groupName || !this.getTenantGroups().includes(groupName) || 'Group already exists',
  ];

  async created() {
    if (!this.tenantId) return;
    const tenantGroupsInfo = (store.getters[GettersTypes.GET_TENANT_GROUPS_INFO] as MultiTenantGroupsInfo)[this.tenantId];
    Object.keys(tenantGroupsInfo).forEach(groupGUID => {
      this.tenantGroups.push(tenantGroupsInfo[groupGUID].groupName);
    });
    if (this.tenantGroups.length) return;
    try {
      const res = await getTenantGroupList(this.tenantId);
      store.dispatch(ActionsTypes.SET_TENANT_GROUPS_INFO, { tenantId: this.tenantId, groupsInfo: compatibleTenantGroupsInfo(res) });
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Failed to fetch existing groups info', TOAST.ERROR);
    }
  }

  @Watch('applyClicked')
  async submitRequest() {
    if (!(this.$refs.addGroupFormRef as Vue & { validate: () => boolean }).validate()) return;
    if (!this.tenantId) return;
    try {
      this.loading = true;
      const groupData = await createTenantGroup(this.tenantId, this.newGroupName, this.newGroupDescription);
      store.dispatch(ActionsTypes.PUSH_TENANT_GROUPS_INFO, { tenantId: this.tenantId, groupInfo: compatibleTenantGroupsInfo([{ groupName: groupData.groupName, groupGUID: groupData.groupGUID, groupDescription: groupData.groupDescription }]) });
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Group created successfully with default permission', TOAST.SUCCESS);
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Failed to create group', TOAST.ERROR);
    } finally {
      this.loading = false;
    }
    this.$dialog.hideAddGroupDialog();
  }
}
