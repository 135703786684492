import { cloneDeep } from 'lodash';
import {
  BasePermission,
  Item,
  MetadataMapping,
  MetadataHistory,
  Email,
  TenantConfig,
  Users,
  Groups,
  Activity,
  GetTenantGroupPermissionPayload,
  GetTenantGroupPermissionResponse,
  UpdateTenantGroupPayload,
  BasePermissionV2,
  GetTenantGroupPermissionResponseV2,
  UpdateTenantGroupPayloadV2,
} from '@qmu/common/dto/userGroupsDto';
import { ChildrenNode, ParentNode } from '@/utils/Types/TreeviewObjectType';
import store, { GettersTypes } from '@/store';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import { get, patch } from './rest';
import { MultiTenantGroupsInfo } from '@/utils/Types/TenantGroupsInfoType';
import { basePermission, basePermissionV2 } from '@qmu/common/defaults/basePermission';
import { isOldPermission } from '@qmu/common/util/isOldPermission';
import { getKeySortedPermissionObject } from '@qmu/common/util/getKeySortedPermissionObject';

export const getGroupPermission = async (groupGUID: string) => {
  const selectedTenant = store.getters[GettersTypes.GET_SELECTED_TENANT] as string;
  const { tenantGroup: url } = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
  const payload: GetTenantGroupPermissionPayload = {
    tenantId: selectedTenant,
    groupGUID: groupGUID,
  };

  const res = await get(url, { params: payload });
  if (isOldPermission(res.data.data.permission)) return (res.data.data as GetTenantGroupPermissionResponse).permission;

  return (res.data.data as GetTenantGroupPermissionResponseV2).permission;
};

export const updateGroup = async (groupGUID: string, groupName?: string, groupDescription?: string, permission?: Partial<BasePermission>) => {
  const { tenantGroup: url } = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
  const tenantId = store.getters[GettersTypes.GET_SELECTED_TENANT] as string;
  const payload: UpdateTenantGroupPayload = {
    groupGUID,
    tenantId,
    groupName,
    groupDescription,
    permission,
  };

  const res = await patch(url, { data: payload });
  const groupData = res.data.data as GetTenantGroupPermissionResponse;
  const currentGroupInfo = (store.getters[GettersTypes.GET_TENANT_GROUPS_INFO] as MultiTenantGroupsInfo)[tenantId];
  if (currentGroupInfo[groupData.groupGUID]) {
    currentGroupInfo[groupData.groupGUID].groupName = groupData.groupName;
    currentGroupInfo[groupData.groupGUID].groupDescription = groupData.groupDescription;
  }
  return groupData.permission;
  // TODO: Update store with
};

export const updateGroupV2 = async (groupGUID: string, groupName?: string, groupDescription?: string, permissionId?: Array<string>) => {
  const { tenantGroups: url } = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
  const tenantId = store.getters[GettersTypes.GET_SELECTED_TENANT] as string;
  const payload: UpdateTenantGroupPayloadV2 = {
    groupGUID,
    tenantId,
    groupName,
    groupDescription,
    permission: permissionId,
  };

  const res = await patch(url, { data: payload });
  const groupData = res.data.data as GetTenantGroupPermissionResponseV2;
  const currentGroupInfo = (store.getters[GettersTypes.GET_TENANT_GROUPS_INFO] as MultiTenantGroupsInfo)[tenantId];
  if (currentGroupInfo[groupData.groupGUID]) {
    currentGroupInfo[groupData.groupGUID].groupName = groupData.groupName;
    currentGroupInfo[groupData.groupGUID].groupDescription = groupData.groupDescription;
  }
  return groupData.permission;
  // TODO: Update store with
};

export const getBasePermission = async () => {
  // TODO: fetch basePermission from DynamoDB
  return basePermission;
};

export const getBasePermissionV2 = async () => {
  return cloneDeep(basePermissionV2);
};

export const getBasePermissionTreeViewObject = (basePermissionObject: BasePermission) => {
  const rootKeys = Object.keys(basePermissionObject) as Array<keyof BasePermission>;
  const treeViewObject: ParentNode[] = [];
  rootKeys.forEach(key => treeViewObject.push(getParentObject(key, basePermissionObject[key])));
  return treeViewObject;
};

export const getGroupPermissionObject = (basePermissionObject: BasePermission, selectedPermissionArray: string[]) => {
  const itemKeys = Object.keys(basePermissionObject) as Array<keyof BasePermission>;
  selectedPermissionArray.forEach(permission =>
    itemKeys.forEach(key => {
      const levels = permission.split('_');
      if (!(Object.keys(basePermissionObject[key]).indexOf(levels[1]) !== -1 && levels[0] === key)) return;
      ((basePermissionObject[key] as Record<string, boolean>)[levels[1]] as boolean) = true;
    })
  );
  return basePermissionObject;
};

export const getUserSelectedPermissionId = (groupPermissionObject: BasePermission) => {
  const rootKeys = Object.keys(groupPermissionObject) as Array<keyof BasePermission>;
  const selectedPermissionId: string[] = [];
  rootKeys.forEach(rootKey =>
    Object.keys(groupPermissionObject[rootKey]).forEach(key => {
      if (!(groupPermissionObject[rootKey] as Record<string, boolean>)[key]) return;
      selectedPermissionId.push(`${rootKey + '_' + key}`);
    })
  );
  return selectedPermissionId;
};

export const getParentObject = (rootKey: string, childObject: Item | MetadataMapping | MetadataHistory | Email | TenantConfig | Users | Groups | Activity) => {
  const parentObject: ParentNode = {
    id: rootKey,
    name: rootKey,
  };
  const children: ChildrenNode[] = [];

  Object.keys(childObject).forEach(key => {
    let childNode = {
      id: `${rootKey + '_' + key}`,
      name: key,
    };
    children.push(childNode);
  });
  parentObject.children = children;
  return parentObject;
};

//new base permission compatible function

export const getBasePermissionTreeViewObjectV2 = (permissions: BasePermissionV2) => {
  permissions = getKeySortedPermissionObject(permissions);
  const keys = Object.keys(permissions);
  let length = keys.length;
  const treeViewObject: ParentNode[] = [];
  let flag = keys[0].split('/')[0].concat('/');
  let items: Array<string> = [];

  keys.forEach(item => {
    length--;
    if (!length) {
      items.push(item);
      treeViewObject.push(getParentObjectV2(items));
      return;
    }
    if (item.startsWith(flag)) {
      items.push(item);
      return;
    }
    treeViewObject.push(getParentObjectV2(items));
    items = [];
    items.push(item);
    flag = item.split('/')[0].concat('/');
  });

  return treeViewObject;
};

export const getParentObjectV2 = (properties: Array<string>) => {
  const parentName = properties[0].split('/')[0];
  const parentObject: ParentNode = {
    id: parentName,
    name: parentName,
  };
  const children: ChildrenNode[] = [];

  properties.forEach(item => {
    const itemName = item.split('/')[1];
    let childNode = {
      id: item,
      name: itemName,
    };
    children.push(childNode);
  });
  parentObject.children = children;
  return parentObject;
};

export const getUserSelectedPermissionIdV2 = (permission: BasePermissionV2) => {
  const keys = Object.keys(permission);
  const selectedPermissionId: string[] = [];
  keys.forEach(id => {
    if (permission[id]) selectedPermissionId.push(id);
  });
  return selectedPermissionId;
};
