import { GetUsersInGroupPayload, DeleteUserAccountPayload, InviteUsersPayload, AssignUserToGroupPayloadV2, RemoveUserFromGroupPayloadV2 } from '@qmu/common/dto/UserInfoDto';
import { ListUserInGroupResponse } from '@qmu/common/dto//UserInfoDto';
import { ClientConfig } from '@qmu/common/dto//ClientConfigDto';
import { ServiceLinks } from '@qmu/common/dto//ServiceDocumentDtos';
import { get, post } from './rest';
import store, { GettersTypes } from '@/store';
import { toString } from '@qmu/common/util/general';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import { BasePermissionV2 } from '@qmu/common/dto/userGroupsDto';
export interface UserDataModel {
  username: string;
  enabled: string;
  userCreatedAt: string;
  userStatus: string;
  emailVerified?: string;
  email?: string;
  groups?: Array<string>;
  userId?: string;
  tenantId?: string;
}

type InviteUsersEmail = Array<string>;

export interface InviteUserResponse {
  failedInvitation: InviteUsersEmail;
  existUser: InviteUsersEmail;
}

export interface UserPoolDataModel {
  getUsersOfGroup(groupName: string): Promise<Array<UserDataModel>>;
  getGroupsOfUser(tenantId: string, userId: string): Promise<Array<string>>;
  assignUserToGroup(groupGUIDs: Array<string>, tenantId: string, userId: string): Promise<boolean>;
  removeUserFromGroup(groupGUIDs: Array<string>, tenantId: string, userId: string): Promise<boolean>;
  deleteUserAccountWithData(username: string, tenantId: string, userId: string): Promise<boolean>;
  inviteUsers(tenantId: string, usersEmail: Array<string>): Promise<InviteUserResponse>;
  getUserMergeGroupPermission(tenantId: string, userId: string): Promise<BasePermissionV2 | {}>;
}

export class UserPoolData implements UserPoolDataModel {
  serviceLinks: ServiceLinks | null = null;
  clientConfig: ClientConfig | null = null;

  constructor() {
    this.serviceLinks = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
    this.clientConfig = store.getters[GettersTypes.GET_CLIENT_CONFIGS] as ClientConfig;
  }

  async getUsersOfGroup(groupName: string): Promise<Array<UserDataModel>> {
    let users: Array<UserDataModel> = [];
    if (!(this.clientConfig && this.serviceLinks)) return users;
    const payload: GetUsersInGroupPayload = {
      groupName: groupName,
      userPoolId: this.clientConfig.userPoolId,
    };

    try {
      const resp = await post(this.serviceLinks.getUsersOfGroup, { data: payload });
      if (!resp.data.data) return users;
      const userListInGroup: ListUserInGroupResponse | null = resp.data.data;
      if (!userListInGroup?.Users) return users;
      for (const user of userListInGroup.Users) {
        const info: UserDataModel = {
          username: toString(user.Username),
          enabled: toString(user.Enabled),
          userCreatedAt: toString(user.UserCreateDate),
          userStatus: toString(user.UserStatus),
        };

        if (!user.Attributes) return users;
        for (const attribute of user.Attributes) {
          switch (attribute.Name) {
            case 'email':
              info.email = attribute.Value;
              break;
            case 'email_verified':
              info.emailVerified = attribute.Value;
              break;
            default:
              break;
          }
        }
        users.push(info);
      }
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Failed to get Users from group', TOAST.ERROR);
    }
    return users;
  }

  async getGroupsOfUser(tenantId: string, userId: string): Promise<Array<string>> {
    if (!(this.clientConfig && this.serviceLinks)) return [];
    const payload = { tenantId, userId };

    try {
      const resp = await post(this.serviceLinks.getGroupsOfUser, { data: payload });
      const memberOfGroups: string[] | null = resp.data.data;
      return memberOfGroups ?? [];
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Failed to get groups of current user', TOAST.ERROR);
      return [];
    }
  }

  async assignUserToGroup(groupGUIDs: Array<string>, tenantId: string, userId: string): Promise<boolean> {
    if (!(this.clientConfig && this.serviceLinks)) return false;
    const payload: AssignUserToGroupPayloadV2 = {
      tenantId,
      userId,
      groupGUIDs,
    };
    try {
      const resp = await post(this.serviceLinks.assignGroupToUser, { data: payload });
      if (!resp.data.data) throw new Error('Failed to assign group');
      return resp.data.data;
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Failed to assign group', TOAST.ERROR);
      return false;
    }
  }

  async removeUserFromGroup(groupGUIDs: Array<string>, tenantId: string, userId: string): Promise<boolean> {
    if (!(this.clientConfig && this.serviceLinks)) return false;
    const payload: RemoveUserFromGroupPayloadV2 = {
      tenantId,
      userId,
      groupGUIDs,
    };
    try {
      const resp = await post(this.serviceLinks.removeGroupFromUser, { data: payload });
      if (!resp.data.data) throw new Error('Failed to remove group');
      return resp.data.data;
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Failed to remove group', TOAST.ERROR);
      return false;
    }
  }

  async deleteUserAccountWithData(username: string, tenantId: string, userId: string): Promise<boolean> {
    if (!(this.clientConfig && this.serviceLinks)) return false;
    const payload: DeleteUserAccountPayload = {
      username,
      userId,
      userPoolId: this.clientConfig.userPoolId,
      userTenantId: tenantId,
    };
    try {
      const resp = await post(this.serviceLinks.deleteUserAccount, { data: payload });
      return resp.data.data ? true : false;
    } catch (error) {
      return false;
    }
  }

  async inviteUsers(tenantId: string, usersEmail: Array<string>): Promise<InviteUserResponse> {
    if (!(this.clientConfig && this.serviceLinks)) throw new Error('Store is not updated. Please refresh the browser');
    const payload: InviteUsersPayload = {
      tenantId,
      usersEmail,
      userPoolId: this.clientConfig.userPoolId,
    };
    const res = await post(this.serviceLinks.inviteUsers, { data: payload });
    return res.data.data as InviteUserResponse;
  }

  async getUserMergeGroupPermission(tenantId: string, userId: string) {
    if (!(this.clientConfig && this.serviceLinks)) return {};
    const { userPermission: url } = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
    const payload = {
      tenantId,
      userId,
    };
    try {
      const resp = await get(url, { params: payload });
      return resp.data.data as BasePermissionV2;
    } catch (err) {
      return {};
    }
  }
}
