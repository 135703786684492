import { FilePart, FileTracker, FileUpload } from './Types/UploadTypes';
import { ItemCreate, ItemState } from '@qmu/common/dto/itemModels';
import { ItemMetaData } from '@qmu/common/dto/mdfModels';
import { createItem, createSignedUrl } from './apiCalls';
import { get, post } from '@/common/rest';
import { FileTrackerDto, FileTrackerUpdateDto } from '@qmu/common/dto/FileTrackerDto';
import { TenantConfig } from '@qmu/common/dto/TenentConfigDtos';
import store, { ActionsTypes, GettersTypes } from '@/store';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import { MimirMetadataDto } from '@qmu/common/dto/MetadataModels';

export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));
export const MaximumRemainingTime = 10000000;

export const MB = 1024 * 1024;

export const getUploadChunk = (size: number) => (size / 10000 < 5 * MB ? 5 * MB : size / 10000);

export type UploadFileInfo = {
  id: string;
  title: string;
  fileId: string;
};

export const getFilePartsInfo = (file: File) => {
  const filePartsInfo = [];
  const uploadChunk = getUploadChunk(file.size);
  const totalParts = Math.ceil(file.size / uploadChunk);
  let start = 0;

  let currentPart = 1;
  while (currentPart <= totalParts) {
    let end = Math.min(start + uploadChunk, file.size);
    filePartsInfo.push({
      PartNumber: currentPart,
      start,
      end,
    });
    start = end;
    currentPart++;
  }

  return filePartsInfo;
};

export const createSignedUrlForPartsAndGetUpdatedFilePartsInfo = async (itemId: string, partsInfoList: FilePart[]) => {
  return await Promise.all(
    partsInfoList.map(async part => ({
      ...part,
      signedUrl: await createSignedUrl(itemId, part.PartNumber),
    }))
  );
};

export const getMetaDataFromFile = (file: File): ItemMetaData => ({
  formId: 'testFormId',
  formData: {
    testData: 'test',
  },
});

export const createItemObjectFromFile = (file: File): ItemCreate => ({
  userEmail: store.getters[GettersTypes.GET_USER_EMAIL],
  notificationStatus: store.getters[GettersTypes.GET_SUBSCRIPTION_INFO],
  isEmailSent: false,
  itemState: ItemState.NEW,
  originalFileName: file.name,
  title: file.name,
  metadata: getMetaDataFromFile(file),
  fileTracker: {
    remainingTime: NaN,
    fileIndex: 0,
    name: file.name,
    speed: 0,
    uploaded: 0,
    realPercentage: 0,
    percentage: 0,
    error: false,
    fileProcessingStarted: false,
    uploadIdGenerated: false,
  },
});

export const getFileInfo = async (file: FileUpload): Promise<UploadFileInfo> => {
  const item: ItemCreate = createItemObjectFromFile(file);
  const {
    data: {
      data: { id, title, fileId },
    },
  } = await createItem(item);
  return { id, title, fileId };
};

export const updateFileTrackerInDb = async (itemId: string, tracker: FileTracker, state: ItemState, metadata?: MimirMetadataDto) => {
  const url = (store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks).itemTrackers;
  //re assignment is important as tracker is coming from store
  const track: FileTrackerDto = {
    realPercentage: tracker.realPercentage,
    remainingTime: tracker.remainingTime,
    error: tracker.error,
    fileIndex: tracker.fileIndex,
    fileProcessingStarted: tracker.fileProcessingStarted,
    name: tracker.name,
    percentage: tracker.percentage,
    speed: tracker.speed,
    uploadIdGenerated: tracker.uploadIdGenerated,
    uploaded: tracker.uploaded,
  };

  const payload: FileTrackerUpdateDto = {
    itemId: itemId,
    fileTrackers: track,
    state: state,
  };
  if (metadata) Object.assign(payload, { metadata });

  await post(url, { data: payload });
};

export const getCurrentUplaodCount = async (url: string) => {
  try {
    const response = await get(url);
    if (response.data.data) return response.data.data.currentlyUploading as number;
    return (store.getters[GettersTypes.GET_TENANT_CONFIG] as TenantConfig).maxParallelUplaodCount as number;
  } catch (err) {
    throw new Error('Parallel Upload count not found');
  }
};
export const makeMetadataJsonFileName = (fileId: string): string => {
  return `metadata_${fileId}.json`;
};

export const getTruncateMiddleOfText = (title: string): string => {
  const titleLength = title.length;
  if (titleLength > 22) {
    return title.substring(0, 12) + '...' + title.substring(titleLength - 6, titleLength);
  }
  return title;
};

export const abortAllFiles = () => {
  const fileTrackers = store.getters[GettersTypes.GET_FILE_TRACKERS] as FileTracker[];
  for (const fileTracker of fileTrackers) {
    fileTracker.abortController.abort(); // abort current running upload.
  }
};

export const clearAllActivityUpdateRepeatInterval = async () => {
  for (const interval of store.getters[GettersTypes.GET_ACTIVITY_UPDATE_TIME_INTERVALS]) clearInterval(interval);
  store.dispatch(ActionsTypes.RESET_ACTIVITY_UPDATE_TIME_INTERVALS);
};
