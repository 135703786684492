

















import { Mapping, MdfType } from '@/common/xmpUtils';
import store, { ActionsTypes, GettersTypes } from '@/store';
import { changeDateFormatToEuropean, getDateFormatFromISODateTime, getNowTime24H, getTimeFormatFromISODateTime, getTodaysDate } from '@qmu/common/util/formatData';
import { MimirMdfCacheDto } from '@qmu/common/dto/MetadataModels';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DateComponent extends Vue {
  @Prop() readonly mdf!: MdfType;
  @Prop() readonly itemLabel!: string;

  date: string | null = null;
  menu: boolean = false;
  time: string = `${getNowTime24H()}`;

  get dataObject() {
    return store.getters[GettersTypes.GET_METADATA_FIELD_VALUES];
  }

  get formattedDate() {
    return `${this.date ? changeDateFormatToEuropean(this.date) : ''} ${this.date ? this.time : ''}`;
  }

  set formattedDate(date: string) {
    this.date = date;
  }

  get mappingData() {
    return (store.getters[GettersTypes.GET_MIMIR_MDF_CACHE] as MimirMdfCacheDto).mappingData;
  }

  get metadataHistoryFlag() {
    return store.getters[GettersTypes.GET_METADATA_HISTORY_FLAG];
  }

  set metadataHistoryFlag(value: boolean) {
    store.dispatch(ActionsTypes.SET_METADATA_HISTORY_FLAG, value);
  }

  getDefaultDateFlag() {
    if (!this.mappingData) return false;
    return this.mappingData[this.mdf.id].defaultDateFlag ?? false;
  }

  async mounted() {
    if (this.dataObject[this.mdf.fieldId]) {
      this.time = getTimeFormatFromISODateTime(this.dataObject[this.mdf.fieldId]);
      this.date = getDateFormatFromISODateTime(this.dataObject[this.mdf.fieldId]);
    } else if (this.getDefaultDateFlag()) {
      this.date = getTodaysDate();
      this.time = getNowTime24H();
      this.dataObject[this.mdf.fieldId] = new Date(this.getDateTime()).toISOString();
    }

    if (this.date && this.time) this.saveDate();
  }

  clearDate() {
    this.dataObject[this.mdf.fieldId] = '';
    this.date = '';
  }

  saveDate() {
    this.menu = false;
    const dateTimeToUTC = new Date(this.getDateTime()).toISOString();
    const data: Mapping = { value: dateTimeToUTC, id: this.mdf.fieldId };
    this.$emit('dateData', data);
  }

  getDateTime() {
    return `${this.date} ${this.time}`;
  }

  get dateLabel() {
    if (this.mdf.required) return `${this.itemLabel}*`;
    return this.itemLabel;
  }
}
