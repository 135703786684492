




























































































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import { DialogRequest, PleaseWaitRequest, SimpleDialogRequest, DialogType, EditDialogRequest, GroupHandlingRequest, AddGroupRequest, AddInviteUsersRequest } from '@/dialog';
import DialogFactory from '@/components/common/DialogFactory.vue';
import SimpleDialog from '@/components/common/dialogs/SimpleDialog.vue';
import PleaseWaitDialog from '@/components/common/dialogs/PleaseWaitDialog.vue';
import GroupHandlingDialog from '@/components/common/dialogs/GroupHandlingDialog.vue';
import AddGroupDialog from '@/components/common/dialogs/AddGroupDialog.vue';
import Login from '../public/Login.vue';
import { Auth } from 'aws-amplify';
import Upload from '../Upload.vue';
import store, { ActionsTypes, GettersTypes } from '@/store';
import { UserInfo } from '@qmu/common/dto/UserInfoDto';
import { UserType } from '@qmu/common/enum/UserTypes';
import { TenantConfig } from '@qmu/common/dto/TenentConfigDtos';
import { bootStrapper } from '@/bootstrapClient';
import Loading from '@/components/common/Loading.vue';
import { routePathMaps } from '@/router/routeData';
import { updateUserSession, UserSessionData } from '@/common/userSession';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import SelectTenant from '@/admin/components/SelectTenant.vue';
import { getCognitoConfig } from '@qmu/common/creds/cognito-creds';
import InviteUsersDialog from '@/components/common/dialogs/InviteUsersDialog.vue';

@Component({
  components: {
    DialogFactory,
    PleaseWaitDialog,
    SimpleDialog,
    GroupHandlingDialog,
    AddGroupDialog,
    Login,
    Upload,
    Loading,
    SelectTenant,
    InviteUsersDialog,
  },
})
export default class Layout extends Vue {
  @Prop({ default: false }) accountState!: boolean;
  @Prop({ default: false }) restarting!: boolean;
  dialogRequest: DialogRequest | null = null;
  waitDialogRequest: PleaseWaitRequest | null = null;
  groupHandlingDialogRequest: GroupHandlingRequest | null = null;
  addGroupDialogRequest: AddGroupRequest | null = null;
  simpleDialogRequest: SimpleDialogRequest | null = null;
  editDialogRequest: EditDialogRequest | null = null;
  inviteUsersDialogRequest: AddInviteUsersRequest | null = null;
  bootstrapped = false;
  listenerAdded = false;
  authenticated: boolean = false;
  drawer: boolean = false;
  isLoading = false;
  navGroup = null;
  getTenantLogo() {
    return (store.getters[GettersTypes.GET_TENANT_CONFIG] as TenantConfig)?.tenantLogo ?? '';
  }

  async signOut() {
    if ((store.getters[GettersTypes.GET_TENANT_ID] as string) === 'dpa') {
      const awsConfig = getCognitoConfig(store.getters[GettersTypes.GET_CLIENT_CONFIGS]);
      const currentDomain = `${window.location.protocol}//${window.location.host}`;
      awsConfig.oauth.redirectSignOut = `https://sso.dpa-id.de/cas/logout?service=${currentDomain}/`;
      Auth.configure(awsConfig);
    }
    await Auth.signOut();
    EventBus.$emit(EVENTS.SHOW_TOAST, 'Logged out', TOAST.WARNING);
    this.drawer = false;
    this.authenticated = false;
    this.resetStore();
  }

  isLargeScreen() {
    return this.$vuetify.breakpoint.smAndUp;
  }

  setAuthenticationStatus(value: boolean) {
    this.authenticated = value;
  }

  snackbar = {
    isVisible: false,
    color: TOAST.DEFAULT,
    message: '',
  };

  get selectedAppBarIndex() {
    return store.getters[GettersTypes.GET_APP_BAR_SELECTED_INDEX];
  }

  set selectedAppBarIndex(value: number) {
    store.dispatch(ActionsTypes.SET_APP_BAR_SELECTED_INDEX, value);
  }

  get routeMaps() {
    return routePathMaps;
  }

  onDialogRequest(req: DialogRequest | null) {
    if (req?.dialogType === DialogType.WAIT) {
      this.waitDialogRequest = req as PleaseWaitRequest;
    } else if (req?.dialogType === DialogType.SIMPLE) {
      this.simpleDialogRequest = req as SimpleDialogRequest;
    } else if (req?.dialogType === DialogType.GROUP_HANDLING) {
      this.groupHandlingDialogRequest = req as GroupHandlingRequest;
    } else if (req?.dialogType === DialogType.ADD_GROUP) {
      this.addGroupDialogRequest = req as AddGroupRequest;
    } else if (req?.dialogType === DialogType.INVITE_USERS) {
      this.inviteUsersDialogRequest = req as AddInviteUsersRequest;
    } else {
      this.dialogRequest = req;
    }
  }

  resetStore() {
    store.dispatch(ActionsTypes.RESET_STATE);
  }

  onDialogHideRequest(type: DialogType) {
    if (this.waitDialogRequest?.dialogType === type) {
      this.waitDialogRequest = null;
    } else if (this.simpleDialogRequest?.dialogType === type) {
      this.simpleDialogRequest = null;
    } else if (this.editDialogRequest?.dialogType === type) {
      this.editDialogRequest = null;
    } else if (this.groupHandlingDialogRequest?.dialogType === type) {
      this.groupHandlingDialogRequest = null;
    } else if (this.addGroupDialogRequest?.dialogType === type) {
      this.addGroupDialogRequest = null;
    } else if (this.inviteUsersDialogRequest?.dialogType === type) {
      this.inviteUsersDialogRequest = null;
    } else if (this.dialogRequest?.dialogType === type) {
      this.dialogRequest = null;
    }
  }

  async created() {
    this.isLoading = true;
    await bootStrapper();
    store.dispatch(ActionsTypes.SET_INITIAL_LOAD, false);
    this.isLoading = false;
    this.drawer = false;
    try {
      await Auth.currentAuthenticatedUser();
      this.authenticated = true;
    } catch (error) {
      this.authenticated = false;
    }
    this.preventWindowClose();
  }

  async mounted() {
    this.drawer = false;
    EventBus.$on(EVENTS.SHOW_TOAST, this.showToast);
    EventBus.$on(EVENTS.SHOW_DIALOG, this.onDialogRequest);
    EventBus.$on(EVENTS.HIDE_DIALOG, this.onDialogHideRequest);
    this.$vuetify.theme.dark = localStorage.getItem('qmu-theme') === 'dark';
    this.preventDragAndDrop();
    if (this.bootstrapped) {
      return;
    }
  }

  preventWindowClose() {
    window.addEventListener('beforeunload', this.leaving);
  }

  async leaving(event: BeforeUnloadEvent) {
    if (!store.getters[GettersTypes.GET_USER_SESSION]) return;
    event.returnValue = '';
    try {
      const payload: UserSessionData = {
        session: false,
        tenantId: store.getters[GettersTypes.GET_TENANT_ID],
        userId: store.getters[GettersTypes.GET_USER_ID],
      };
      updateUserSession(this.specificUserLink, payload);
    } catch (e) {
      console.log(`Error while leaving: ${e}`);
    }
  }

  get specificUserLink() {
    return (store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks).updateSpecificUser;
  }

  get userType() {
    return UserType;
  }

  get userGroup() {
    const group = store.getters[GettersTypes.GET_USER_GROUP] as UserInfo;
    if (group && group === UserType.READONLY_USER) this.blockUserAccess();
    return group ?? null;
  }

  blockUserAccess() {
    this.$dialog.showSimpleDialog(
      {
        icon: 'mdi-account-alert',
        maxWidth: 430,
        header: 'Access-denied',
        baseHtml: 'Sorry! It seems that you do not have access rights to Q-Upload. Please contact your service desk / administrator.<br> By pressing "OK" you will be <b style="color:red">logged out.<b>',
        infoOnly: true,
        noAutoClose: true,
      },
      () => this.signOut()
    );
  }

  showToast(message: string, color: string, timeout?: number) {
    this.snackbar.color = color ? color : TOAST.DEFAULT;
    this.snackbar.message = message;
    this.snackbar.isVisible = true;

    /*
      TODO: Find a better way to rediscover toast messages after they time out.
    */
    console.log(message);
  }

  enableTheme(themeName: string) {
    this.$vuetify.theme.dark = themeName === 'dark';
    localStorage.setItem('qmu-theme', themeName);
  }

  get isHomePage() {
    if (this.$route.path === '/') return true;
    return false;
  }

  get isDashboard() {
    if (this.$route.path === this.routeMaps.dashBoard.path) return true;
    return false;
  }

  get isSystemSetting() {
    if (this.$route.path === this.routeMaps.systemSetting.path) return true;
    return false;
  }

  // this prevent browser from loading a drag-and-dropped file
  preventDragAndDrop() {
    window.addEventListener('dragover', event => this.makeEventDefault(event), false);
    window.addEventListener('drop', event => this.makeEventDefault(event), false);
  }

  makeEventDefault(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer) event.dataTransfer.dropEffect = 'none';
  }
}
