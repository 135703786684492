import store, { GettersTypes } from '@/store';
import { TenantGroupBasicInfo, TenantGroupsInfo } from '@/utils/Types/TenantGroupsInfoType';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import { CreateTenantGroupPayload, DeleteTenantGroupPayload, GetTenantGroupPermissionResponse, GetTenantGroupsList } from '@qmu/common/dto/userGroupsDto';
import { DELETE, get, post } from './rest';

export const getTenantGroupList = async (tenantId: string) => {
  const { tenantGroups: url } = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
  if (!url) throw new Error('Please refresh the browser. Tenant group list URL not found');
  const resp = await get(url, { params: { tenantId } });
  return (resp.data?.data ?? []) as GetTenantGroupsList;
};

export const compatibleTenantGroupsInfo = (groupList: GetTenantGroupsList) => {
  const groups: TenantGroupsInfo = {};
  groupList.forEach(item => {
    const { groupGUID, ...rest } = item;
    groups[item.groupGUID] = { ...rest };
  });
  return groups;
};

export const createTenantGroup = async (tenantId: string, groupName: string, groupDescription: string) => {
  const { tenantGroup: url } = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
  if (!url) throw new Error('Please refresh the browser.Tenant group creation url not found');
  const payload: CreateTenantGroupPayload = {
    tenantId,
    groupName,
    groupDescription,
  };
  const resp = await post(url, { data: payload });
  if (!resp.data?.data) throw new Error('Tenant group creation failed');
  return resp.data.data as GetTenantGroupPermissionResponse;
};

export const deleteTenantGroup = async (tenantId: string, groupGUID: string) => {
  const { tenantGroup: url } = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
  if (!url) throw new Error('Please refresh the browser.Tenant group creation url not found');
  const payload: DeleteTenantGroupPayload = {
    tenantId,
    groupGUID,
  };
  await DELETE(url, { data: payload });
};
