
























import InviteUsers from '@/admin/components/InviteUsers.vue';
import { AddInviteUsersRequest } from '@/dialog';
import DialogMixin from '@/mixins/dialog-mixin';
import { mixins } from 'vue-class-component';
import { Component, Vue } from 'vue-property-decorator';
import BaseDialog from './BaseDialog.vue';

@Component({
  components: {
    BaseDialog,
    InviteUsers,
  },
})
export default class InviteUsersDialog extends mixins<DialogMixin<AddInviteUsersRequest>>(DialogMixin) {
  loading = false;
  applyClicked = false;
  header = `Invite Users`;
  /**
   * Sending update to child
   */
  actionClicked() {
    this.applyClicked = !this.applyClicked;
  }

  cancelClicked() {
    // DO something on cancel clicked
  }
}
