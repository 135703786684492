


























import { Component } from 'vue-property-decorator';
import { AddGroupRequest } from '@/dialog';
import { mixins } from 'vue-class-component';
import DialogMixin from '@/mixins/dialog-mixin';
import BaseDialog from './BaseDialog.vue';
import AddGroup from '@/admin/components/AddGroup.vue';
@Component({
  components: {
    BaseDialog,
    AddGroup,
  },
})
export default class AddGroupDialog extends mixins<DialogMixin<AddGroupRequest>>(DialogMixin) {
  loading = false;
  applyClicked = false;
  header = `Add group for (Tenant: ${this.req.tenantId})`;
  /**
   * Sending update to child
   */
  actionClicked() {
    this.applyClicked = !this.applyClicked;
  }

  cancelClicked() {
    // DO something on cancel clicked
  }
}
