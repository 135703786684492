import eventBus, { EVENTS, TOAST } from '@/eventBus';
import store, { ActionsTypes, GettersTypes } from '@/store';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import { get, post } from './rest';

export type UserSessionData = {
  session: boolean;
  tenantId: string;
  userId: string;
};

export const updateUserSession = async (link: string, payload: { session: boolean }) => {
  try {
    await post(link, { data: payload });
    store.dispatch(ActionsTypes.SET_USER_SESSION, payload.session);
  } catch (err) {
    eventBus.$emit(EVENTS.SHOW_TOAST, 'Something went wrong updating session.', TOAST.WARNING);
  }
};

export const getUserSession = async () => {
  try {
    const link = (store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks).getSpecificUser as string;
    const params = {
      tenantId: store.getters[GettersTypes.GET_TENANT_ID],
      userId: store.getters[GettersTypes.GET_USER_ID],
    };
    const response = await get(link, { params });
    if (response.data.data?.session) return response.data.data.session;
  } catch (err) {
    eventBus.$emit(EVENTS.SHOW_TOAST, 'Something went wrong.', TOAST.WARNING);
  }
  return null;
};

export const getStoreSession = () => store.getters[GettersTypes.GET_USER_SESSION];

export const checkSession = async () => {
  const sessionFromServer = await getUserSession();
  return (sessionFromServer && getStoreSession()) || (!sessionFromServer && !getStoreSession());
};
