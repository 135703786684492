import { TenantConfig, GetTenantConfigQueryParamPayload, UpdateTenantConfigPayload, CreateTenantConfigPayload } from '@qmu/common/dto/TenentConfigDtos';
import { get, post } from '@/common/rest';
import store, { GettersTypes } from '@/store';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';

const getTenantConfigEndpoint = (): string => {
  return (store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks).tenantConfig;
};

/**
 * Return tenant config from server.
 * @param tenantId
 * @returns TenantConfig
 */
const getTenantConfig = async (tenantId: string): Promise<TenantConfig> => {
  const url = getTenantConfigEndpoint();
  if (!url) throw new Error('Failed to read tenant config url from store');
  const getTenantConfigQueryParamPayload: GetTenantConfigQueryParamPayload = {
    tenantId,
  };
  const resp = await get(url, { params: getTenantConfigQueryParamPayload });
  const tenantConfig: TenantConfig = resp.data.data;
  if (!tenantConfig) throw new Error('Failed to get Tenant Config');
  return tenantConfig;
};

/**
 * Get tenant config of the specified tenantId
 * @param tenantId
 * @param createIfNotExists - if set to true, it will create tenant config if doesnt exists then return it
 * @returns TenantConfig if everything is ok
 * @throws Error() if tenantConfig fetching fails
 */
export const getTenantConfigForced = async (tenantId: string, createIfNotExists = true): Promise<TenantConfig> => {
  try {
    return await getTenantConfig(tenantId);
  } catch (error) {
    if (!(error?.response?.status === 404 && createIfNotExists)) throw new Error('Faild to get tenant config');
    if (await createDefaultTenantConfig(tenantId)) return getTenantConfig(tenantId);
    throw new Error('Faild to get tenant config');
  }
};

/**
 * Update tenant config with the speficied update payload
 * @param tenantConfig
 * @returns true if success else false
 */
export const updateTenantConfig = async (tenantConfig: UpdateTenantConfigPayload): Promise<boolean> => {
  const url = getTenantConfigEndpoint();
  if (!url) return false;
  try {
    const resp = await post(url, { data: tenantConfig });
    if (resp.status === 200) return true;
  } catch (error) {}
  return false;
};

/**
 * Create default teanant config if does not exists in the database
 * @param tenantId - for which tenant config will be created
 * @returns true if created else false
 */
export const createDefaultTenantConfig = async (tenantId: string): Promise<boolean> => {
  const createDefaultTenantConfigPayload: UpdateTenantConfigPayload = {
    tenantId,
  };
  return updateTenantConfig(createDefaultTenantConfigPayload);
};

export const createUserDefineTenantConfig = async (tenantConfig: CreateTenantConfigPayload) => {
  const { tenant: url } = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
  if (!url) return false;
  await post(url, { data: tenantConfig });
  return true;
};
