import NotificationType from '../enum/NotificationType';
import Ajv, { JSONSchemaType, ValidateFunction, ErrorObject } from 'ajv';
import addFormats from 'ajv-formats';
import { IdentityVerificationAttributes } from '@aws-sdk/client-ses';
import { FileInfoTypeForSendingEmail } from './FileTrackerDto';

export enum EmailTemplateType {
  SUCCESS = 'success',
  FAILED = 'failed',
  NEW_USER = 'newUser',
}
export interface SubscriptionResponse<T> {
  status: boolean;
  message: string;
  result?: Partial<T>;
  errors?: ErrorObject<string, Record<string, any>, unknown>[];
}
export interface SubscriptionPayload {
  type?: NotificationType;
  address: string;
  status: boolean;
}
export interface SubscriptionStatusQueryData {
  tenantId: string;
  userId: string;
}
export interface SubscriptionStatusResponse {
  status: Record<string, string | number | boolean> | undefined;
}
export interface EmailTemplatePlaceHolders {
  userName: string;
  message: string;
}
export interface EmailTemplatePayload {
  htmlContent: string;
  textContent?: string;
  subject: string;
  templateName: string;
}

export interface SESEmailIdentityPayload {
  email: string;
}
export interface SESIdentity {
  [key: string]: IdentityVerificationAttributes;
}
export interface GetSESIdentitiesResponse {
  identities: SESIdentity | undefined;
  currentFromAddress: string;
}

export function isNotificationType(st: string): st is NotificationType {
  return Object.values(NotificationType).includes(st as NotificationType);
}

export interface DpaEmailTemplateData {
  user: string;
  metadataTitle: string;
  germanDateTime: string;
  zeusIdPretified: string;
  uploadedFileNames: FileInfoTypeForSendingEmail[];
  uploadDate: string;
}

export enum EmailReceipentType {
  USER = 'user',
  ADMIN = 'admin',
  ALL = 'userAndAdmin',
}

export interface DpaEmailTemplatePayload {
  receipentType: EmailReceipentType;
  templateType: EmailTemplateType;
  recipientEmail?: string;
  templateData: DpaEmailTemplateData;
}

export const validatePayload = (() => {
  const ajv = new Ajv();
  const toggleSubscriptionInputSchema: JSONSchemaType<SubscriptionPayload> = {
    type: 'object',
    properties: {
      type: {
        type: 'string',
        enum: Object.values(NotificationType),
        nullable: true,
      },
      address: {
        type: 'string',
      },
      status: {
        type: 'boolean',
      },
    },
    required: ['address', 'status'],
  };
  const validate = ajv.compile(toggleSubscriptionInputSchema);

  return (payload: SubscriptionPayload): ValidateFunction<unknown> => {
    validate(payload);
    return validate;
  };
})();

export const validateSubscriptionStatusPayload = (() => {
  const ajv = new Ajv();
  const getSubscriptionInputSchema: JSONSchemaType<SubscriptionStatusQueryData> = {
    type: 'object',
    properties: {
      tenantId: {
        type: 'string',
      },
      userId: {
        type: 'string',
      },
    },
    required: ['tenantId', 'userId'],
  };
  const validate = ajv.compile(getSubscriptionInputSchema);

  return (payload: SubscriptionStatusQueryData): ValidateFunction<unknown> => {
    validate(payload);
    return validate;
  };
})();

export const validateCreateEmailTemplatePayload = (() => {
  const ajv = new Ajv();
  const createEmailTemplatePayload: JSONSchemaType<EmailTemplatePayload> = {
    type: 'object',
    properties: {
      htmlContent: { type: 'string' },
      textContent: { type: 'string', nullable: true },
      subject: { type: 'string' },
      templateName: { type: 'string' },
    },
    required: ['htmlContent', 'subject', 'templateName'],
  };
  const validate = ajv.compile(createEmailTemplatePayload);

  return (payload: EmailTemplatePayload): ValidateFunction<unknown> => {
    validate(payload);
    return validate;
  };
})();

export const validateDpaEmailTemplatePayload = (() => {
  const ajv = new Ajv();
  const dpaEmailTemplatePayload: JSONSchemaType<DpaEmailTemplatePayload> = {
    type: 'object',
    properties: {
      receipentType: {
        type: 'string',
        nullable: false,
        enum: Object.values(EmailReceipentType),
      },
      recipientEmail: { type: 'string', nullable: true },
      templateType: {
        type: 'string',
        nullable: false,
        enum: Object.values(EmailTemplateType),
      },
      templateData: {
        type: 'object',
        properties: {
          user: { type: 'string', nullable: false },
          metadataTitle: { type: 'string', nullable: false },
          uploadDate: { type: 'string', nullable: false },
          germanDateTime: { type: 'string', nullable: false },
          uploadedFileNames: {
            type: 'array',
            nullable: false,
            items: {
              type: 'object',
              nullable: false,
              properties: {
                itemId: { type: 'string', nullable: false },
                fileName: { type: 'string', nullable: false },
              },
              required: ['itemId', 'fileName'],
            },
          },
          zeusIdPretified: { type: 'string', nullable: false },
        },
        required: ['user', 'metadataTitle', 'uploadDate', 'germanDateTime', 'uploadedFileNames', 'zeusIdPretified'],
      },
    },
    required: ['templateType', 'templateData', 'receipentType'],
  };
  const validate = ajv.compile(dpaEmailTemplatePayload);

  return (payload: DpaEmailTemplatePayload): ValidateFunction<unknown> => {
    validate(payload);
    return validate;
  };
})();

export const emailValidator = (() => {
  const ajv = new Ajv();
  addFormats(ajv);
  const emailValidatorSchema: JSONSchemaType<SESEmailIdentityPayload> = {
    type: 'object',
    properties: {
      email: { type: 'string', format: 'email' },
    },
    required: ['email'],
  };
  const validate = ajv.compile(emailValidatorSchema);

  return (payload: SESEmailIdentityPayload): ValidateFunction<SESEmailIdentityPayload> => {
    validate(payload);
    return validate;
  };
})();
