import Vue from 'vue';
import Vuex from 'vuex';
import { ServiceDocument, ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import { ClientConfig } from '@qmu/common/dto/ClientConfigDto';
import { TenantConfig } from '@qmu/common/dto/TenentConfigDtos';
import { UserInfo } from '@qmu/common/dto/UserInfoDto';
import { DefaultMimirCache, MetadataType, MimirMdfCacheDto } from '@qmu/common/dto/MetadataModels';

import { MutationTree } from 'vuex';
import { ActionTree, ActionContext } from 'vuex';
import { GetterTree } from 'vuex';
import { ParsedJwt } from '@qmu/common/format/jwt';
import { CompleteUploadFlag, FileStatus, FileTracker, FileUpload } from './utils/Types/UploadTypes';
import { find } from 'lodash';
import { GetMdfFileFieldsSchema, GetMdfFileViewsItemSchema, GetMdfFileViewsUploadSchema, MappingDataSchema } from '@qmu/common/dto/mimirDto';
import { TenantUserAppendToList, TenantUserList, TenantUserListMap, TenantUserListMapAppendToList } from '@qmu/common/dto/userDataDto';
import { FileInfoTypeForSendingEmail } from '@qmu/common/dto/FileTrackerDto';
import { TenantGroupsInfo, MultiTenantGroupsInfo } from './utils/Types/TenantGroupsInfoType';
import { BasePermissionV2 } from '@qmu/common/dto/userGroupsDto';

export interface StateType {
  drawer: boolean;
  initialLoad: boolean;
  serviceDocs: ServiceDocument | undefined;
  user: UserInfo | undefined;
  jwtIdToken: ParsedJwt | undefined;
  fileTrackers: FileTracker[];
  files: FileUpload[];
  fileStatus: FileStatus;
  uploadStart: boolean;
  emailSubscriptionInfo: boolean | undefined;
  metadataFieldStatus: boolean;
  metadataResetFlag: boolean;
  MetaDataFieldValues: MetadataType;
  tenantConfig: TenantConfig | undefined;
  mimirMdfCache: MimirMdfCacheDto;
  completedFileNamesToEmail: FileInfoTypeForSendingEmail[];
  failedFileNamesToEmail: FileInfoTypeForSendingEmail[];
  tenantList: string[];
  selectedTenant: string | null;
  metadataFromXmpFlag: boolean;
  tenantUserList: TenantUserList;
  tenantUserListEmailUserIdMap: TenantUserListMap;
  tenantUserListUserNameUserIdMap: TenantUserListMap;
  tenantUserListUserIdUserEmailMap: TenantUserListMap;
  metadataFromHistoryFlag: boolean;
  xmpEmail: string | null;
  userSession: boolean;
  dialogCounter: number;
  completeUploadFlags: Array<CompleteUploadFlag>;
  activityUpdateTimeIntervals: NodeJS.Timeout[];
  appBarSelectedIndex: number;
  tenantGroupsInfo: MultiTenantGroupsInfo;
  groupListUpdatedFlag: boolean;
  userMergeGroupsPermission: BasePermissionV2;
}

export const initialSate = (): StateType => {
  return {
    drawer: false,
    initialLoad: true,
    serviceDocs: undefined,
    user: undefined,
    jwtIdToken: undefined,
    emailSubscriptionInfo: undefined,
    fileTrackers: [],
    files: [],
    fileStatus: {},
    uploadStart: true, // this triggers upload to start, can be controlled from UI
    metadataFieldStatus: false, //to track if the user has saved metadata in upload page
    metadataResetFlag: false,
    MetaDataFieldValues: {},
    tenantConfig: undefined,
    mimirMdfCache: DefaultMimirCache(),
    completedFileNamesToEmail: [],
    failedFileNamesToEmail: [],
    tenantList: [],
    selectedTenant: null,
    metadataFromXmpFlag: false,
    tenantUserList: {},
    tenantUserListEmailUserIdMap: {},
    tenantUserListUserNameUserIdMap: {},
    tenantUserListUserIdUserEmailMap: {},
    metadataFromHistoryFlag: false,
    xmpEmail: null,
    userSession: false,
    dialogCounter: 0,
    completeUploadFlags: [],
    activityUpdateTimeIntervals: [],
    appBarSelectedIndex: 0,
    tenantGroupsInfo: {},
    groupListUpdatedFlag: false,
    userMergeGroupsPermission: {},
  };
};

export const state: StateType = initialSate();

export enum MutationTypes {
  TOGGLE_DRAWER = 'toggleDrawer',
  SET_INITIAL_LOAD = 'setInitialLoad',
  RESET_STATE = 'resetState',
  SET_SERVICE_DOCS = 'setServiceDocs',
  SET_USER_INFO = 'setUserInfo',
  SET_JWT_ID_TOKEN = 'setJwtIdToken',
  SET_EMAIL_SUBSCRIPTION_INFO = 'setEmailSubscriptionInfo',
  ADD_FILE_UPLOAD_TRACKER = 'addFileUploadTracker',
  ADD_FILE_TRACKER = 'addFileTracker',
  UPDATE_UPLOAD_START = 'update_upload_start',
  UPDATE_METADATA_FIELD_STATUS = 'updateMetadataFieldStatus',
  RESET_METADATA_FIELD_VALUES = 'resetMetadataFieldValues',
  SET_METADATA_FIELDS = 'setMetadataFields',
  SET_FILE_TRACKER = 'setFileTracker',
  SET_FILES = 'setFiles',
  SET_FILE_STATUS = 'setFileStatus',
  SET_MIMIR_CACHE_MDF = 'setMimirCacheMdf',
  SET_MIMIR_CACHE_MAPPING_DATA = 'setMimirCaseMappingData',
  SET_MIMIR_CACHE_MDF_ITEMS = 'setMimirCacheMdfItems',
  SET_MIMIR_CACHE_MDF_UPLOADS = 'setMimirCacheMdfUploads',
  SET_MIMIR_CACHE_MDF_ID_ROH = 'setMimirCacheMdfIdRoh',
  SET_COMPLETED_FILE_INFO_TO_EMAIL = 'setCompletedFilesToEmail',
  CLEAR_COMPLETED_FILE_INFO_TO_EMAIL = 'clearCompletedFilesToEmail',
  SET_FAILED_FILE_INFO_TO_EMAIL = 'setFailedFilesToEmail',
  CLEAR_FAILED_FILE_INFO_TO_EMAIL = 'clearFailedFilesToEmail',
  SET_TENANT_CONFIG = 'setTenantConfig',
  SET_TENANT_LIST = 'setTenantList',
  DELETE_UPLOAD_SESSION = 'deleteUploadSession',
  SET_SELECTED_TENANAT = 'setSelectedTenant',
  SET_METADATA_XMP_FLAG = 'setMetadataXmpFlag',
  SET_TENANT_USER_LIST = 'setTenantUserList',
  SET_TENANT_USER_LIST_EMAIL_USER_ID_MAP = 'setTenantUserListEmailUserIdMap',
  SET_TENANT_USER_LIST_USER_NAME_USER_ID_MAP = 'setTenantUserListUserNameUserIdMap',
  SET_TENANT_USER_LIST_USER_ID_USER_EMAIL_MAP = 'setTenantUserListUserIdUserEmailMap',
  SET_METADATA_HISTORY_FLAG = 'setMetadataHistoryFlag',
  SET_XMP_EMAIL = 'setXmpEmail',
  SET_USER_SESSION = 'setUserSession',
  SET_METADATA_RESET_FLAG = 'setMetadataResetFlag',
  INCREASE_DIALOG_COUNTER = 'increaseDialogCounter',
  DECREASE_DIALOG_COUNTER = 'decreaseDialogCounter',
  UPDATE_COMPLETE_UPLOAD_FLAGS = 'updateCompleteUploadFlags',
  ADD_ACTIVITY_UPDATE_TIME_INTERVAL = 'addActivityUpdateTimeInterval',
  RESET_ACTIVITY_UPDATE_TIME_INTERVALS = 'resetActivityUpdateTimeIntervals',
  SET_APP_BAR_SELECTED_INDEX = 'setAppBarSelectedIndex',
  SET_TENANT_GROUPS_INFO = 'setTenantGroupsInfo',
  PUSH_TENANT_GROUPS_INFO = 'pushTenantGroupsInfo',
  REMOVE_TENANT_GROUPS_INFO = 'removeTenantGroupsInfo',
  SET_USER_MERGE_GROUPS_PERMISSION = 'setUserMergeGroupsPermission',
}

export interface Mutations<S = StateType> {
  [MutationTypes.TOGGLE_DRAWER](state: S): void;
  [MutationTypes.SET_INITIAL_LOAD](state: S, payload: boolean): void;
  [MutationTypes.RESET_STATE](state: S): void;
  [MutationTypes.SET_SERVICE_DOCS](state: S, payload: ServiceDocument): void;
  [MutationTypes.SET_USER_INFO](state: S, payload: UserInfo): void;
  [MutationTypes.SET_JWT_ID_TOKEN](state: S, payload: ParsedJwt): void;
  [MutationTypes.ADD_FILE_TRACKER](state: S, payload: FileTracker): void;
  [MutationTypes.SET_EMAIL_SUBSCRIPTION_INFO](state: S, payload: boolean): void;
  [MutationTypes.UPDATE_UPLOAD_START](state: S, payload: boolean): void;
  [MutationTypes.UPDATE_METADATA_FIELD_STATUS](state: S, payload: boolean): void;
  [MutationTypes.RESET_METADATA_FIELD_VALUES](state: S, payload: MetadataType): void;
  [MutationTypes.SET_METADATA_FIELDS](state: S, payload: MetadataType): void;
  [MutationTypes.SET_FILE_TRACKER](state: S, payload: FileTracker[]): void;
  [MutationTypes.SET_FILES](state: S, payload: FileUpload[]): void;
  [MutationTypes.SET_FILE_STATUS](state: S, payload: FileStatus): void;
  [MutationTypes.SET_MIMIR_CACHE_MDF](state: S, payload: GetMdfFileFieldsSchema[]): void;
  [MutationTypes.SET_MIMIR_CACHE_MAPPING_DATA](state: S, payload: MappingDataSchema): void;
  [MutationTypes.SET_MIMIR_CACHE_MDF_ITEMS](state: S, payload: GetMdfFileViewsItemSchema[]): void;
  [MutationTypes.SET_MIMIR_CACHE_MDF_UPLOADS](state: S, payload: GetMdfFileViewsUploadSchema[]): void;
  [MutationTypes.SET_MIMIR_CACHE_MDF_ID_ROH](state: S, payload: string): void;
  [MutationTypes.SET_COMPLETED_FILE_INFO_TO_EMAIL](state: S, payload: FileInfoTypeForSendingEmail): void;
  [MutationTypes.CLEAR_COMPLETED_FILE_INFO_TO_EMAIL](state: S, payload: FileInfoTypeForSendingEmail[]): void;
  [MutationTypes.SET_FAILED_FILE_INFO_TO_EMAIL](state: S, payload: FileInfoTypeForSendingEmail): void;
  [MutationTypes.CLEAR_FAILED_FILE_INFO_TO_EMAIL](state: S, payload: FileInfoTypeForSendingEmail[]): void;
  [MutationTypes.SET_TENANT_CONFIG](state: S, payload: TenantConfig): void;
  [MutationTypes.SET_TENANT_LIST](state: S, payload: Array<string>): void;
  [MutationTypes.DELETE_UPLOAD_SESSION](state: S): void;
  [MutationTypes.SET_SELECTED_TENANAT](state: S, payload: string): void;
  [MutationTypes.SET_METADATA_XMP_FLAG](state: S, payload: boolean): void;
  [MutationTypes.SET_TENANT_USER_LIST](state: S, payload: TenantUserAppendToList): void;
  [MutationTypes.SET_TENANT_USER_LIST_EMAIL_USER_ID_MAP](state: S, payload: TenantUserListMapAppendToList): void;
  [MutationTypes.SET_TENANT_USER_LIST_USER_NAME_USER_ID_MAP](state: S, payload: TenantUserListMapAppendToList): void;
  [MutationTypes.SET_TENANT_USER_LIST_USER_ID_USER_EMAIL_MAP](state: S, payload: TenantUserListMapAppendToList): void;
  [MutationTypes.SET_METADATA_HISTORY_FLAG](state: S, payload: boolean): void;
  [MutationTypes.SET_XMP_EMAIL](state: S, payload: string | null): void;
  [MutationTypes.SET_USER_SESSION](state: S, payload: boolean): void;
  [MutationTypes.INCREASE_DIALOG_COUNTER](state: S): void;
  [MutationTypes.DECREASE_DIALOG_COUNTER](state: S): void;
  [MutationTypes.SET_METADATA_RESET_FLAG](state: S): void;
  [MutationTypes.UPDATE_COMPLETE_UPLOAD_FLAGS](state: S, payload: CompleteUploadFlag): void;
  [MutationTypes.ADD_ACTIVITY_UPDATE_TIME_INTERVAL](state: S, payload: NodeJS.Timeout): void;
  [MutationTypes.RESET_ACTIVITY_UPDATE_TIME_INTERVALS](state: S): void;
  [MutationTypes.SET_APP_BAR_SELECTED_INDEX](state: S, payload: number): void;
  [MutationTypes.SET_TENANT_GROUPS_INFO](state: S, payload: { tenantId: string; groupInfo: TenantGroupsInfo }): void;
  [MutationTypes.PUSH_TENANT_GROUPS_INFO](state: S, payload: { tenantId: string; groupInfo: TenantGroupsInfo }): void;
  [MutationTypes.REMOVE_TENANT_GROUPS_INFO](state: S, payload: { tenantId: string; groupGUID: string }): void;
  [MutationTypes.SET_USER_MERGE_GROUPS_PERMISSION](state: S, payload: BasePermissionV2): void;
}

export const mutations: MutationTree<StateType> & Mutations = {
  [MutationTypes.TOGGLE_DRAWER](state) {
    state.drawer != state.drawer;
  },
  [MutationTypes.SET_INITIAL_LOAD](state, payload) {
    state.initialLoad = payload;
  },
  [MutationTypes.RESET_STATE](state) {
    Object.assign(state, initialSate());
  },
  [MutationTypes.SET_SERVICE_DOCS](state, payload) {
    state.serviceDocs = payload;
  },
  [MutationTypes.SET_USER_INFO](state, payload) {
    state.user = payload;
  },
  [MutationTypes.SET_JWT_ID_TOKEN](state, payload) {
    state.jwtIdToken = payload;
  },
  [MutationTypes.SET_EMAIL_SUBSCRIPTION_INFO](state, payload) {
    state.emailSubscriptionInfo = payload;
  },
  [MutationTypes.ADD_FILE_TRACKER](state, payload) {
    state.fileTrackers.push(payload);
  },
  [MutationTypes.UPDATE_UPLOAD_START](state, payload) {
    state.uploadStart = payload;
  },
  [MutationTypes.UPDATE_METADATA_FIELD_STATUS](state, payload) {
    state.metadataFieldStatus = payload;
  },
  [MutationTypes.RESET_METADATA_FIELD_VALUES](state, payload) {
    state.MetaDataFieldValues = payload;
  },
  [MutationTypes.SET_METADATA_FIELDS](state, payload) {
    state.MetaDataFieldValues = payload;
  },
  [MutationTypes.SET_FILE_TRACKER](state, payload) {
    state.fileTrackers = payload;
  },
  [MutationTypes.SET_FILES](state, payload) {
    state.files = payload;
  },
  [MutationTypes.SET_FILE_STATUS](state, payload) {
    state.fileStatus = payload;
  },
  [MutationTypes.SET_MIMIR_CACHE_MDF](state, payload) {
    state.mimirMdfCache.mdf = payload;
  },
  [MutationTypes.SET_MIMIR_CACHE_MAPPING_DATA](state, payload) {
    state.mimirMdfCache.mappingData = payload;
  },
  [MutationTypes.SET_MIMIR_CACHE_MDF_ITEMS](state, payload) {
    state.mimirMdfCache.mdfItems = payload;
  },
  [MutationTypes.SET_MIMIR_CACHE_MDF_UPLOADS](state, payload) {
    state.mimirMdfCache.mdfUpload = payload;
  },
  [MutationTypes.SET_MIMIR_CACHE_MDF_ID_ROH](state, payload) {
    state.mimirMdfCache.mdfIdRoh = payload;
  },
  [MutationTypes.SET_COMPLETED_FILE_INFO_TO_EMAIL](state, payload) {
    state.completedFileNamesToEmail.push(payload);
  },
  [MutationTypes.CLEAR_COMPLETED_FILE_INFO_TO_EMAIL](state, payload) {
    for (let index = 0; index < state.completedFileNamesToEmail.length; index++) {
      if (payload.some(item => item.itemId === state.completedFileNamesToEmail[index].itemId)) {
        state.completedFileNamesToEmail.splice(index, 1);
        index--;
      }
    }
  },
  [MutationTypes.SET_FAILED_FILE_INFO_TO_EMAIL](state, payload) {
    state.failedFileNamesToEmail.push(payload);
  },
  [MutationTypes.CLEAR_FAILED_FILE_INFO_TO_EMAIL](state, payload) {
    for (let index = 0; index < state.failedFileNamesToEmail.length; index++) {
      if (payload.some(item => item.itemId === state.failedFileNamesToEmail[index].itemId)) {
        state.failedFileNamesToEmail.splice(index, 1);
        index--;
      }
    }
  },
  [MutationTypes.SET_TENANT_CONFIG](state, payload) {
    state.tenantConfig = payload;
  },
  [MutationTypes.SET_TENANT_LIST](state, payload) {
    state.tenantList = payload;
  },
  [MutationTypes.DELETE_UPLOAD_SESSION](state) {
    state.fileTrackers = [];
    state.files = [];
    state.fileStatus = {};
    state.uploadStart = true;
    state.metadataFieldStatus = false;
    state.MetaDataFieldValues = {};
  },
  [MutationTypes.SET_SELECTED_TENANAT](state, payload) {
    state.selectedTenant = payload;
  },
  [MutationTypes.SET_METADATA_XMP_FLAG](state, payload) {
    state.metadataFromXmpFlag = payload;
  },
  [MutationTypes.SET_TENANT_USER_LIST](state, payload) {
    state.tenantUserList[payload.key] = payload.data;
  },
  [MutationTypes.SET_TENANT_USER_LIST_EMAIL_USER_ID_MAP](state, payload) {
    if (!state.tenantUserListEmailUserIdMap[payload.key1]) state.tenantUserListEmailUserIdMap[payload.key1] = {};
    state.tenantUserListEmailUserIdMap[payload.key1][payload.key2] = payload.data;
  },
  [MutationTypes.SET_TENANT_USER_LIST_USER_NAME_USER_ID_MAP](state, payload) {
    if (!state.tenantUserListUserNameUserIdMap[payload.key1]) state.tenantUserListUserNameUserIdMap[payload.key1] = {};
    state.tenantUserListUserNameUserIdMap[payload.key1][payload.key2] = payload.data;
  },
  [MutationTypes.SET_TENANT_USER_LIST_USER_ID_USER_EMAIL_MAP](state, payload) {
    if (!state.tenantUserListUserIdUserEmailMap[payload.key1]) state.tenantUserListUserIdUserEmailMap[payload.key1] = {};
    state.tenantUserListUserIdUserEmailMap[payload.key1][payload.key2] = payload.data;
  },
  [MutationTypes.SET_METADATA_HISTORY_FLAG](state, payload) {
    state.metadataFromHistoryFlag = payload;
  },
  [MutationTypes.SET_XMP_EMAIL](state, payload) {
    state.xmpEmail = payload;
  },
  [MutationTypes.SET_USER_SESSION](state, payload) {
    state.userSession = payload;
  },
  [MutationTypes.SET_METADATA_RESET_FLAG](state) {
    state.metadataResetFlag = !state.metadataResetFlag;
  },
  [MutationTypes.INCREASE_DIALOG_COUNTER](state) {
    state.dialogCounter += 1;
  },
  [MutationTypes.DECREASE_DIALOG_COUNTER](state) {
    state.dialogCounter -= 1;
  },
  [MutationTypes.UPDATE_COMPLETE_UPLOAD_FLAGS](state, payload) {
    let flag = false;
    state.completeUploadFlags.forEach(element => {
      if (element.key === payload.key) {
        element.key = payload.key;
        element.value = payload.value;
        flag = true;
      }
    });
    if (!flag) state.completeUploadFlags.push(payload);
  },
  [MutationTypes.ADD_ACTIVITY_UPDATE_TIME_INTERVAL](state, payload) {
    state.activityUpdateTimeIntervals.push(payload);
  },
  [MutationTypes.RESET_ACTIVITY_UPDATE_TIME_INTERVALS](state) {
    state.activityUpdateTimeIntervals.length = 0;
  },
  [MutationTypes.SET_APP_BAR_SELECTED_INDEX](state, payload) {
    state.appBarSelectedIndex = payload;
  },
  [MutationTypes.SET_TENANT_GROUPS_INFO](state, payload) {
    state.tenantGroupsInfo[payload.tenantId] = payload.groupInfo;
  },
  [MutationTypes.PUSH_TENANT_GROUPS_INFO](state, payload) {
    if (!state.tenantGroupsInfo[payload.tenantId]) state.tenantGroupsInfo[payload.tenantId] = payload.groupInfo;
    else Object.assign(state.tenantGroupsInfo[payload.tenantId], payload.groupInfo);
    state.groupListUpdatedFlag = !state.groupListUpdatedFlag;
  },

  [MutationTypes.REMOVE_TENANT_GROUPS_INFO](state, payload) {
    if (state.tenantGroupsInfo[payload.tenantId]) delete state.tenantGroupsInfo[payload.tenantId][payload.groupGUID];
    state.groupListUpdatedFlag = !state.groupListUpdatedFlag;
  },
  [MutationTypes.SET_USER_MERGE_GROUPS_PERMISSION](state, payload) {
    state.userMergeGroupsPermission = payload;
  },
};

export enum ActionsTypes {
  TOGGLE_DRAWER = 'toggleDrawer',
  SET_INITIAL_LOAD = 'setInitialLoad',
  RESET_STATE = 'resetState',
  SET_SERVICE_DOCS = 'setServiceDocs',
  SET_USER_INFO = 'setUserInfo',
  SET_JWT_ID_TOKEN = 'setJwtIdToken',
  SET_EMAIL_SUBSCRIPTION_INFO = 'setEmailSubscriptionInfo',
  ADD_FILE_TRACKER = 'addFileTracker',
  UPDATE_UPLOAD_START = 'update_upload_start',
  UPDATE_METADATA_FIELD_STATUS = 'updateMetadataFieldStatus',
  RESET_METADATA_FIELD_VALUES = 'resetMetadataFieldValues',
  SET_METADATA_FIELDS = 'setMetadataFields',
  SET_FILE_TRACKER = 'setFileTracker',
  SET_FILES = 'setFiles',
  SET_FILE_STATUS = 'setFileStatus',
  SET_MIMIR_CACHE_MDF = 'setMimirCacheMdf',
  SET_MIMIR_CACHE_MAPPING_DATA = 'setMimirCaseMappingData',
  SET_MIMIR_CACHE_MDF_ITEMS = 'setMimirCacheMdfItems',
  SET_MIMIR_CACHE_MDF_UPLOADS = 'setMimirCacheMdfUploads',
  SET_MIMIR_CACHE_MDF_ID_ROH = 'setMimirCacheMdfIdRoh',
  SET_COMPLETED_FILE_INFO_TO_EMAIL = 'setCompletedFileInfoToEmail',
  CLEAR_COMPLETED_FILE_INFO_TO_EMAIL = 'clearCompletedFileInfoToEmail',
  SET_FAILED_FILE_INFO_TO_EMAIL = 'setFailedFileInfoToEmail',
  CLEAR_FAILED_FILE_INFO_TO_EMAIL = 'clearFailedFileInfoToEmail',
  SET_TENANT_CONFIG = 'setTenantConfig',
  SET_TENANT_LIST = 'setTenantList',
  DELETE_UPLOAD_SESSION = 'deleteUploadSession',
  SET_SELECTED_TENANAT = 'setSelectedTenant',
  SET_METADATA_XMP_FLAG = 'setMetadataXmpFlag',
  SET_TENANT_USER_LIST = 'setTenantUserList',
  SET_TENANT_USER_LIST_EMAIL_USER_ID_MAP = 'setTenantUserListEmailUserIdMap',
  SET_TENANT_USER_LIST_USER_NAME_USER_ID_MAP = 'setTenantUserListUserNameUserIdMap',
  SET_TENANT_USER_LIST_USER_ID_USER_EMAIL_MAP = 'setTenantUserListUserIdUserEmailMap',
  SET_METADATA_HISTORY_FLAG = 'setMetadataHistoryFlag',
  SET_XMP_EMAIL = 'setXmpEmail',
  SET_USER_SESSION = 'setUserSession',
  SET_METADATA_RESET_FLAG = 'setMetadataResetFlag',
  INCREASE_DIALOG_COUNTER = 'increaseDialogCounter',
  DECREASE_DIALOG_COUNTER = 'decreaseDialogCounter',
  UPDATE_COMPLETE_UPLOAD_FLAGS = 'updateCompleteUploadFlags',
  ADD_ACTIVITY_UPDATE_TIME_INTERVAL = 'addActivityUpdateTimeInterval',
  RESET_ACTIVITY_UPDATE_TIME_INTERVALS = 'resetActivityUpdateTimeIntervals',
  SET_APP_BAR_SELECTED_INDEX = 'setAppBarSelectedIndex',
  SET_TENANT_GROUPS_INFO = 'setTenantGroupsInfo',
  PUSH_TENANT_GROUPS_INFO = 'pushTenantGroupsInfo',
  REMOVE_TENANT_GROUPS_INFO = 'removeTenantGroupsInfo',
  SET_USER_MERGE_GROUPS_PERMISSION = 'setUserMergeGroupsPermission',
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<StateType, StateType>, 'commit'>;

export interface Actions {
  [ActionsTypes.TOGGLE_DRAWER]({ commit }: AugmentedActionContext): void;
  [ActionsTypes.SET_INITIAL_LOAD]({ commit }: AugmentedActionContext, payload: boolean): void;
  [ActionsTypes.RESET_STATE]({ commit }: AugmentedActionContext): void;
  [ActionsTypes.SET_SERVICE_DOCS]({ commit }: AugmentedActionContext, payload: any): void;
  [ActionsTypes.SET_USER_INFO]({ commit }: AugmentedActionContext, payload: any): void;
  [ActionsTypes.SET_JWT_ID_TOKEN]({ commit }: AugmentedActionContext, payload: ParsedJwt): void;
  [ActionsTypes.ADD_FILE_TRACKER]({ commit }: AugmentedActionContext, payload: FileTracker): void;
  [ActionsTypes.SET_EMAIL_SUBSCRIPTION_INFO]({ commit }: AugmentedActionContext, payload: boolean): void;
  [ActionsTypes.UPDATE_UPLOAD_START]({ commit }: AugmentedActionContext, payload: boolean): void;
  [ActionsTypes.UPDATE_METADATA_FIELD_STATUS]({ commit }: AugmentedActionContext, payload: boolean): void;
  [ActionsTypes.RESET_METADATA_FIELD_VALUES]({ commit }: AugmentedActionContext, payload: MetadataType): void;
  [ActionsTypes.SET_METADATA_FIELDS]({ commit }: AugmentedActionContext, payload: MetadataType): void;
  [ActionsTypes.SET_FILE_TRACKER]({ commit }: AugmentedActionContext, payload: FileTracker[]): void;
  [ActionsTypes.SET_FILES]({ commit }: AugmentedActionContext, payload: FileUpload[]): void;
  [ActionsTypes.SET_FILE_STATUS]({ commit }: AugmentedActionContext, payload: FileStatus): void;
  [ActionsTypes.SET_MIMIR_CACHE_MDF]({ commit }: AugmentedActionContext, payload: GetMdfFileFieldsSchema[]): void;
  [ActionsTypes.SET_MIMIR_CACHE_MAPPING_DATA]({ commit }: AugmentedActionContext, payload: MappingDataSchema): void;
  [ActionsTypes.SET_MIMIR_CACHE_MDF_ITEMS]({ commit }: AugmentedActionContext, payload: GetMdfFileViewsItemSchema[]): void;
  [ActionsTypes.SET_MIMIR_CACHE_MDF_UPLOADS]({ commit }: AugmentedActionContext, payload: GetMdfFileViewsUploadSchema[]): void;
  [ActionsTypes.SET_MIMIR_CACHE_MDF_ID_ROH]({ commit }: AugmentedActionContext, payload: string): void;
  [ActionsTypes.SET_COMPLETED_FILE_INFO_TO_EMAIL]({ commit }: AugmentedActionContext, payload: FileInfoTypeForSendingEmail): void;
  [ActionsTypes.CLEAR_COMPLETED_FILE_INFO_TO_EMAIL]({ commit }: AugmentedActionContext, payload: FileInfoTypeForSendingEmail[]): void;
  [ActionsTypes.SET_FAILED_FILE_INFO_TO_EMAIL]({ commit }: AugmentedActionContext, payload: FileInfoTypeForSendingEmail): void;
  [ActionsTypes.CLEAR_FAILED_FILE_INFO_TO_EMAIL]({ commit }: AugmentedActionContext, payload: FileInfoTypeForSendingEmail[]): void;
  [ActionsTypes.SET_TENANT_CONFIG]({ commit }: AugmentedActionContext, payload: TenantConfig): void;
  [ActionsTypes.SET_TENANT_LIST]({ commit }: AugmentedActionContext, payload: Array<string>): void;
  [ActionsTypes.DELETE_UPLOAD_SESSION]({ commit }: AugmentedActionContext): void;
  [ActionsTypes.SET_SELECTED_TENANAT]({ commit }: AugmentedActionContext, payload: string): void;
  [ActionsTypes.SET_METADATA_XMP_FLAG]({ commit }: AugmentedActionContext, payload: boolean): void;
  [ActionsTypes.SET_TENANT_USER_LIST]({ commit }: AugmentedActionContext, payload: TenantUserAppendToList): void;
  [ActionsTypes.SET_TENANT_USER_LIST_EMAIL_USER_ID_MAP]({ commit }: AugmentedActionContext, payload: TenantUserListMapAppendToList): void;
  [ActionsTypes.SET_TENANT_USER_LIST_USER_NAME_USER_ID_MAP]({ commit }: AugmentedActionContext, payload: TenantUserListMapAppendToList): void;
  [ActionsTypes.SET_TENANT_USER_LIST_USER_ID_USER_EMAIL_MAP]({ commit }: AugmentedActionContext, payload: TenantUserListMapAppendToList): void;
  [ActionsTypes.SET_METADATA_HISTORY_FLAG]({ commit }: AugmentedActionContext, payload: boolean): void;
  [ActionsTypes.SET_XMP_EMAIL]({ commit }: AugmentedActionContext, payload: string | null): void;
  [ActionsTypes.SET_USER_SESSION]({ commit }: AugmentedActionContext, payload: boolean): void;
  [ActionsTypes.INCREASE_DIALOG_COUNTER]({ commit }: AugmentedActionContext): void;
  [ActionsTypes.DECREASE_DIALOG_COUNTER]({ commit }: AugmentedActionContext): void;
  [ActionsTypes.SET_METADATA_RESET_FLAG]({ commit }: AugmentedActionContext): void;
  [ActionsTypes.UPDATE_COMPLETE_UPLOAD_FLAGS]({ commit }: AugmentedActionContext, payload: { key: string; value: boolean }): void;
  [ActionsTypes.ADD_ACTIVITY_UPDATE_TIME_INTERVAL]({ commit }: AugmentedActionContext, payload: NodeJS.Timeout): void;
  [ActionsTypes.RESET_ACTIVITY_UPDATE_TIME_INTERVALS]({ commit }: AugmentedActionContext): void;
  [ActionsTypes.SET_APP_BAR_SELECTED_INDEX]({ commit }: AugmentedActionContext, payload: number): void;
  [ActionsTypes.SET_TENANT_GROUPS_INFO]({ commit }: AugmentedActionContext, payload: { tenantId: string; groupInfo: TenantGroupsInfo }): void;
  [ActionsTypes.PUSH_TENANT_GROUPS_INFO]({ commit }: AugmentedActionContext, payload: { tenantId: string; groupInfo: TenantGroupsInfo }): void;
  [ActionsTypes.REMOVE_TENANT_GROUPS_INFO]({ commit }: AugmentedActionContext, payload: { tenantId: string; groupGUID: string }): void;
  [ActionsTypes.SET_USER_MERGE_GROUPS_PERMISSION]({ commit }: AugmentedActionContext, payload: BasePermissionV2): void;
}

export const actions: ActionTree<StateType, StateType> & Actions = {
  [ActionsTypes.TOGGLE_DRAWER]({ commit }) {
    commit(MutationTypes.TOGGLE_DRAWER);
  },
  [ActionsTypes.SET_INITIAL_LOAD]({ commit }, payload) {
    commit(MutationTypes.SET_INITIAL_LOAD, payload);
  },
  [ActionsTypes.RESET_STATE]({ commit }) {
    commit(MutationTypes.RESET_STATE);
  },
  [ActionsTypes.SET_SERVICE_DOCS]({ commit }, payload) {
    commit(MutationTypes.SET_SERVICE_DOCS, payload);
  },
  [ActionsTypes.SET_USER_INFO]({ commit }, payload) {
    commit(MutationTypes.SET_USER_INFO, payload);
  },
  [ActionsTypes.SET_JWT_ID_TOKEN]({ commit }, payload) {
    commit(MutationTypes.SET_JWT_ID_TOKEN, payload);
  },
  [ActionsTypes.SET_EMAIL_SUBSCRIPTION_INFO]({ commit }, payload) {
    commit(MutationTypes.SET_EMAIL_SUBSCRIPTION_INFO, payload);
  },
  [ActionsTypes.ADD_FILE_TRACKER]({ commit }, payload) {
    commit(MutationTypes.ADD_FILE_TRACKER, payload);
  },
  [ActionsTypes.UPDATE_UPLOAD_START]({ commit }, payload) {
    commit(MutationTypes.UPDATE_UPLOAD_START, payload);
  },
  [ActionsTypes.UPDATE_METADATA_FIELD_STATUS]({ commit }, payload) {
    commit(MutationTypes.UPDATE_METADATA_FIELD_STATUS, payload);
  },
  [ActionsTypes.RESET_METADATA_FIELD_VALUES]({ commit }, payload) {
    commit(MutationTypes.RESET_METADATA_FIELD_VALUES, payload);
  },
  [ActionsTypes.SET_METADATA_FIELDS]({ commit }, payload) {
    commit(MutationTypes.SET_METADATA_FIELDS, payload);
  },
  [ActionsTypes.SET_FILE_TRACKER]({ commit }, payload) {
    commit(MutationTypes.SET_FILE_TRACKER, payload);
  },
  [ActionsTypes.SET_FILES]({ commit }, payload) {
    commit(MutationTypes.SET_FILES, payload);
  },
  [ActionsTypes.SET_FILE_STATUS]({ commit }, payload) {
    commit(MutationTypes.SET_FILE_STATUS, payload);
  },
  [ActionsTypes.SET_MIMIR_CACHE_MDF]({ commit }, payload) {
    commit(MutationTypes.SET_MIMIR_CACHE_MDF, payload);
  },
  [ActionsTypes.SET_MIMIR_CACHE_MAPPING_DATA]({ commit }, payload) {
    commit(MutationTypes.SET_MIMIR_CACHE_MAPPING_DATA, payload);
  },
  [ActionsTypes.SET_MIMIR_CACHE_MDF_ITEMS]({ commit }, payload) {
    commit(MutationTypes.SET_MIMIR_CACHE_MDF_ITEMS, payload);
  },
  [ActionsTypes.SET_MIMIR_CACHE_MDF_UPLOADS]({ commit }, payload) {
    commit(MutationTypes.SET_MIMIR_CACHE_MDF_UPLOADS, payload);
  },
  [ActionsTypes.SET_MIMIR_CACHE_MDF_ID_ROH]({ commit }, payload) {
    commit(MutationTypes.SET_MIMIR_CACHE_MDF_ID_ROH, payload);
  },
  [ActionsTypes.SET_COMPLETED_FILE_INFO_TO_EMAIL]({ commit }, payload) {
    commit(MutationTypes.SET_COMPLETED_FILE_INFO_TO_EMAIL, payload);
  },
  [ActionsTypes.CLEAR_COMPLETED_FILE_INFO_TO_EMAIL]({ commit }, payload) {
    commit(MutationTypes.CLEAR_COMPLETED_FILE_INFO_TO_EMAIL, payload);
  },
  [ActionsTypes.SET_FAILED_FILE_INFO_TO_EMAIL]({ commit }, payload) {
    commit(MutationTypes.SET_FAILED_FILE_INFO_TO_EMAIL, payload);
  },
  [ActionsTypes.CLEAR_FAILED_FILE_INFO_TO_EMAIL]({ commit }, payload) {
    commit(MutationTypes.CLEAR_FAILED_FILE_INFO_TO_EMAIL, payload);
  },
  [ActionsTypes.SET_TENANT_CONFIG]({ commit }, payload) {
    commit(MutationTypes.SET_TENANT_CONFIG, payload);
  },
  [ActionsTypes.SET_TENANT_LIST]({ commit }, payload) {
    commit(MutationTypes.SET_TENANT_LIST, payload);
  },
  [ActionsTypes.DELETE_UPLOAD_SESSION]({ commit }) {
    commit(MutationTypes.DELETE_UPLOAD_SESSION);
  },
  [ActionsTypes.SET_SELECTED_TENANAT]({ commit }, payload) {
    commit(MutationTypes.SET_SELECTED_TENANAT, payload);
  },
  [ActionsTypes.SET_METADATA_XMP_FLAG]({ commit }, payload) {
    commit(MutationTypes.SET_METADATA_XMP_FLAG, payload);
  },
  [ActionsTypes.SET_TENANT_USER_LIST]({ commit }, payload) {
    commit(MutationTypes.SET_TENANT_USER_LIST, payload);
  },
  [ActionsTypes.SET_TENANT_USER_LIST_EMAIL_USER_ID_MAP]({ commit }, payload) {
    commit(MutationTypes.SET_TENANT_USER_LIST_EMAIL_USER_ID_MAP, payload);
  },
  [ActionsTypes.SET_TENANT_USER_LIST_USER_NAME_USER_ID_MAP]({ commit }, payload) {
    commit(MutationTypes.SET_TENANT_USER_LIST_USER_NAME_USER_ID_MAP, payload);
  },
  [ActionsTypes.SET_TENANT_USER_LIST_USER_ID_USER_EMAIL_MAP]({ commit }, payload) {
    commit(MutationTypes.SET_TENANT_USER_LIST_USER_ID_USER_EMAIL_MAP, payload);
  },
  [ActionsTypes.SET_METADATA_HISTORY_FLAG]({ commit }, payload) {
    commit(MutationTypes.SET_METADATA_HISTORY_FLAG, payload);
  },
  [ActionsTypes.SET_XMP_EMAIL]({ commit }, payload) {
    commit(MutationTypes.SET_XMP_EMAIL, payload);
  },
  [ActionsTypes.SET_USER_SESSION]({ commit }, payload) {
    commit(MutationTypes.SET_USER_SESSION, payload);
  },
  [ActionsTypes.INCREASE_DIALOG_COUNTER]({ commit }) {
    commit(MutationTypes.INCREASE_DIALOG_COUNTER);
  },
  [ActionsTypes.DECREASE_DIALOG_COUNTER]({ commit }) {
    commit(MutationTypes.DECREASE_DIALOG_COUNTER);
  },
  [ActionsTypes.SET_METADATA_RESET_FLAG]({ commit }) {
    commit(MutationTypes.SET_METADATA_RESET_FLAG);
  },
  [ActionsTypes.UPDATE_COMPLETE_UPLOAD_FLAGS]({ commit }, payload) {
    commit(MutationTypes.UPDATE_COMPLETE_UPLOAD_FLAGS, payload);
  },
  [ActionsTypes.ADD_ACTIVITY_UPDATE_TIME_INTERVAL]({ commit }, payload) {
    commit(MutationTypes.ADD_ACTIVITY_UPDATE_TIME_INTERVAL, payload);
  },
  [ActionsTypes.RESET_ACTIVITY_UPDATE_TIME_INTERVALS]({ commit }) {
    commit(MutationTypes.RESET_ACTIVITY_UPDATE_TIME_INTERVALS);
  },
  [ActionsTypes.SET_APP_BAR_SELECTED_INDEX]({ commit }, payload) {
    commit(MutationTypes.SET_APP_BAR_SELECTED_INDEX, payload);
  },
  [ActionsTypes.SET_TENANT_GROUPS_INFO]({ commit }, payload) {
    commit(MutationTypes.SET_TENANT_GROUPS_INFO, payload);
  },
  [ActionsTypes.PUSH_TENANT_GROUPS_INFO]({ commit }, payload) {
    commit(MutationTypes.PUSH_TENANT_GROUPS_INFO, payload);
  },
  [ActionsTypes.REMOVE_TENANT_GROUPS_INFO]({ commit }, payload) {
    commit(MutationTypes.REMOVE_TENANT_GROUPS_INFO, payload);
  },
  [ActionsTypes.SET_USER_MERGE_GROUPS_PERMISSION]({ commit }, payload) {
    commit(MutationTypes.SET_USER_MERGE_GROUPS_PERMISSION, payload);
  },
};

export enum GettersTypes {
  GET_SERVICE_DOCS = 'getServiceDocs',
  GET_INITIAL_LOAD = 'getInitialLoad',
  GET_CLIENT_CONFIGS = 'getClientConfigs',
  GET_SERVICE_LINKS = 'getServiceLinks',
  GET_USER_GROUP = 'getUserGroup',
  GET_USER_JWT_TOKEN = 'getUserJwtToken',
  GET_SUBSCRIPTION_INFO = 'getSubscriptionInfo',
  GET_FILE_TRACKERS = 'getFileTrackers',
  GET_FILE_TRACKER = 'getFileTracker',
  GET_FILES = 'getFiles',
  GET_UPLOAD_START = 'getUploadStart',
  GET_USER_EMAIL = 'getUserEmail',
  GET_ALL_FILE_STATUS = 'getAllFileStatus',
  GET_TENANT_ID = 'getTenantId',
  GET_USER_ID = 'getUserId',
  GET_MDF_INFO_LINK = 'getMdfInfoLink',
  GET_MDF_FILE_LINK = 'getMdfFileLink',
  GET_MDF_MAPPING_LINK = 'getMdfMappingLink',
  GET_MDF_CREATE_MAPPING_LINK = 'getMdfCreateMappingLink',
  GET_MDF_UPDATE_MAPPING_LINK = 'getMdfUpdateMappingLink',
  GET_MDFS_AND_MAPPING_LINK = 'getMdfsAndMappingLink',
  GET_METADATA_ON_S3_LINK = 'getMetadataOnS3Link',
  GET_METADATA_FIELD_STATUS = 'getMetadataFieldStatus',
  GET_METADATA_FIELD_VALUES = 'getMetadataFieldValues',
  GET_TENANT_CONFIG = 'getTenantConfig',
  GET_MIMIR_MDF_CACHE = 'getMimirMdfCache',
  GET_COMPLETED_FILE_INFO_TO_EMAIL = 'getCompletedFileInfoToEmail',
  GET_FAILED_FILE_INFO_TO_EMAIL = 'getFailedFileInfoToEmail',
  GET_TENANT_LIST = 'getTenantList',
  GET_TENANT_ID_LIST_LINK = 'getTenantIdListLink',
  GET_SELECTED_TENANT = 'getSelectedTenant',
  GET_METADATA_FROM_XMP_FLAG = 'getMetadataFromXmpFlag',
  GET_TENANT_USER_LIST = 'getTenantUserList',
  GET_TENANT_USER_LIST_EMAIL_USER_ID_MAP = 'getTenantUserListEmailUserIdMap',
  GET_TENANT_USER_LIST_USER_NAME_USER_ID_MAP = 'getTenantUserListUserNameUserIdMap',
  GET_TENANT_USER_LIST_USER_ID_USER_EMAIL_MAP = 'getTenantUserListUserIdUserEmailMap',
  GET_METADATA_HISTORY_FLAG = 'getMetadataHistoryFlag',
  GET_XMP_EMAIL = 'getXmpEmail',
  GET_USER_SESSION = 'getUserSession',
  GET_TENANT_CONFIG_MAX_HISTORY = 'getTenantConfigMaxHistory',
  GET_METADATA_RESET_FLAG = 'getMetadataResetFlag',
  GET_COMPLETE_UPLOAD_FLAGS = 'getCompleteUploadFlags',
  GET_ACTIVITY_UPDATE_TIME_INTERVALS = 'getActivityUpdateTimeIntervals',
  GET_APP_BAR_SELECTED_INDEX = 'getAppBarSelectedIndex',
  GET_TENANT_GROUPS_INFO = 'getTenantGroupsInfo',
  GET_GROUP_LIST_UPDATED_FLAG = 'getGroupListUpdatedFlag',
  GET_USER_MERGE_GROUPS_PERMISSION = 'getUserMergeGroupsPermission',
}
export interface Getters {
  [GettersTypes.GET_SERVICE_DOCS](state: StateType): ServiceDocument | undefined;
  [GettersTypes.GET_INITIAL_LOAD](state: StateType): boolean;
  [GettersTypes.GET_CLIENT_CONFIGS](state: StateType): ClientConfig | undefined;
  [GettersTypes.GET_SERVICE_LINKS](state: StateType): ServiceLinks | undefined;
  [GettersTypes.GET_USER_GROUP](state: StateType): UserInfo | undefined;
  [GettersTypes.GET_USER_JWT_TOKEN](state: StateType): ParsedJwt | undefined;
  [GettersTypes.GET_FILE_TRACKERS](state: StateType): FileTracker[] | undefined;
  [GettersTypes.GET_FILE_TRACKER](state: StateType, name: string): FileTracker | undefined;
  [GettersTypes.GET_FILES](state: StateType): FileUpload[] | undefined;
  [GettersTypes.GET_UPLOAD_START](state: StateType): boolean;
  [GettersTypes.GET_METADATA_FIELD_STATUS](state: StateType): boolean;
  [GettersTypes.GET_USER_EMAIL](state: StateType): string | undefined;
  [GettersTypes.GET_ALL_FILE_STATUS](state: StateType): FileStatus | undefined;
  [GettersTypes.GET_SUBSCRIPTION_INFO](state: StateType): boolean | undefined;
  [GettersTypes.GET_USER_EMAIL](state: StateType): string | undefined;
  [GettersTypes.GET_TENANT_ID](state: StateType): string | undefined;
  [GettersTypes.GET_USER_ID](state: StateType): string | undefined;
  [GettersTypes.GET_MDF_INFO_LINK](state: StateType): string | undefined;
  [GettersTypes.GET_MDF_FILE_LINK](state: StateType): string | undefined;
  [GettersTypes.GET_MDF_MAPPING_LINK](state: StateType): string | undefined;
  [GettersTypes.GET_MDF_CREATE_MAPPING_LINK](state: StateType): string | undefined;
  [GettersTypes.GET_MDF_UPDATE_MAPPING_LINK](state: StateType): string | undefined;
  [GettersTypes.GET_MDFS_AND_MAPPING_LINK](state: StateType): string | undefined;
  [GettersTypes.GET_METADATA_FIELD_VALUES](state: StateType): MetadataType;
  [GettersTypes.GET_METADATA_ON_S3_LINK](state: StateType): string | undefined;
  [GettersTypes.GET_TENANT_CONFIG](state: StateType): TenantConfig | undefined;
  [GettersTypes.GET_MIMIR_MDF_CACHE](state: StateType): MimirMdfCacheDto;
  [GettersTypes.GET_COMPLETED_FILE_INFO_TO_EMAIL](state: StateType): FileInfoTypeForSendingEmail[];
  [GettersTypes.GET_FAILED_FILE_INFO_TO_EMAIL](state: StateType): FileInfoTypeForSendingEmail[];
  [GettersTypes.GET_TENANT_LIST](state: StateType): string[];
  [GettersTypes.GET_TENANT_ID_LIST_LINK](state: StateType): string | undefined;
  [GettersTypes.GET_SELECTED_TENANT](state: StateType): string | null;
  [GettersTypes.GET_METADATA_FROM_XMP_FLAG](state: StateType): boolean;
  [GettersTypes.GET_TENANT_USER_LIST](state: StateType): TenantUserList;
  [GettersTypes.GET_TENANT_USER_LIST_EMAIL_USER_ID_MAP](state: StateType): TenantUserListMap;
  [GettersTypes.GET_TENANT_USER_LIST_USER_NAME_USER_ID_MAP](state: StateType): TenantUserListMap;
  [GettersTypes.GET_TENANT_USER_LIST_USER_ID_USER_EMAIL_MAP](state: StateType): TenantUserListMap;
  [GettersTypes.GET_METADATA_HISTORY_FLAG](state: StateType): boolean;
  [GettersTypes.GET_XMP_EMAIL](state: StateType): string | null;
  [GettersTypes.GET_USER_SESSION](state: StateType): boolean;
  [GettersTypes.GET_TENANT_CONFIG_MAX_HISTORY](state: StateType): number;
  [GettersTypes.GET_METADATA_RESET_FLAG](state: StateType): boolean;
  [GettersTypes.GET_COMPLETE_UPLOAD_FLAGS](state: StateType): Array<CompleteUploadFlag>;
  [GettersTypes.GET_ACTIVITY_UPDATE_TIME_INTERVALS](state: StateType): NodeJS.Timeout[];
  [GettersTypes.GET_APP_BAR_SELECTED_INDEX](state: StateType): number;
  [GettersTypes.GET_TENANT_GROUPS_INFO](state: StateType): MultiTenantGroupsInfo;
  [GettersTypes.GET_GROUP_LIST_UPDATED_FLAG](state: StateType): boolean;
  [GettersTypes.GET_USER_MERGE_GROUPS_PERMISSION](state: StateType): BasePermissionV2;
}

export const getters: GetterTree<StateType, StateType> & Getters = {
  [GettersTypes.GET_SERVICE_DOCS]: (state: StateType) => {
    return state.serviceDocs;
  },
  [GettersTypes.GET_INITIAL_LOAD]: (state: StateType) => {
    return state.initialLoad;
  },
  [GettersTypes.GET_CLIENT_CONFIGS]: (state: StateType) => {
    return state.serviceDocs?.clientConfig;
  },
  [GettersTypes.GET_SERVICE_LINKS]: (state: StateType) => {
    return state.serviceDocs?.links;
  },
  [GettersTypes.GET_USER_GROUP]: (state: StateType) => {
    return state.user;
  },
  [GettersTypes.GET_USER_JWT_TOKEN]: (state: StateType) => {
    return state.jwtIdToken;
  },
  [GettersTypes.GET_SUBSCRIPTION_INFO]: (state: StateType) => {
    return state.emailSubscriptionInfo;
  },
  [GettersTypes.GET_FILE_TRACKERS]: (state: StateType) => {
    return state.fileTrackers;
  },
  [GettersTypes.GET_FILE_TRACKER]: (state: StateType, name: string) => {
    return find(state.fileTrackers, { name });
  },
  [GettersTypes.GET_FILES]: (state: StateType) => {
    return state.files;
  },
  [GettersTypes.GET_USER_EMAIL]: (state: StateType) => {
    const email = state.jwtIdToken?.payload.email;
    return email ? email : '';
  },
  [GettersTypes.GET_ALL_FILE_STATUS]: (state: StateType) => {
    return state.fileStatus;
  },
  [GettersTypes.GET_TENANT_ID]: (state: StateType) => {
    return state.jwtIdToken?.payload['custom:tenantId'];
  },
  [GettersTypes.GET_USER_ID]: (state: StateType) => {
    return state.jwtIdToken?.payload.sub;
  },
  [GettersTypes.GET_MDF_INFO_LINK]: (state: StateType) => {
    const link = state.serviceDocs?.links.mdfsInfo;
    return link ? link : '';
  },
  [GettersTypes.GET_MDF_FILE_LINK]: (state: StateType) => {
    const link = state.serviceDocs?.links.mdfFile;
    return link ? link : '';
  },
  [GettersTypes.GET_MDF_MAPPING_LINK]: (state: StateType) => {
    const link = state.serviceDocs?.links.mapping;
    return link ? link : '';
  },
  [GettersTypes.GET_MDF_CREATE_MAPPING_LINK]: (state: StateType) => {
    const link = state.serviceDocs?.links.createMappings;
    return link ? link : '';
  },
  [GettersTypes.GET_MDF_UPDATE_MAPPING_LINK]: (state: StateType) => {
    const link = state.serviceDocs?.links.updateMappings;
    return link ? link : '';
  },
  [GettersTypes.GET_MDFS_AND_MAPPING_LINK]: (state: StateType) => {
    const link = state.serviceDocs?.links.mdfsAndMappingsLink;
    return link ? link : '';
  },
  [GettersTypes.GET_METADATA_ON_S3_LINK]: (state: StateType) => {
    const link = state.serviceDocs?.links.metadataOnS3;
    return link ? link : '';
  },
  [GettersTypes.GET_UPLOAD_START]: (state: StateType) => {
    return state.uploadStart;
  },
  [GettersTypes.GET_METADATA_FIELD_STATUS]: (state: StateType) => {
    return state.metadataFieldStatus;
  },
  [GettersTypes.GET_METADATA_FIELD_VALUES]: (state: StateType) => {
    return state.MetaDataFieldValues;
  },
  [GettersTypes.GET_TENANT_CONFIG]: (state: StateType) => {
    return state.tenantConfig;
  },
  [GettersTypes.GET_MIMIR_MDF_CACHE]: (state: StateType) => {
    return state.mimirMdfCache;
  },
  [GettersTypes.GET_COMPLETED_FILE_INFO_TO_EMAIL]: (state: StateType) => {
    // Main array will be cleared in later phase
    return [...state.completedFileNamesToEmail];
  },
  [GettersTypes.GET_FAILED_FILE_INFO_TO_EMAIL]: (state: StateType) => {
    // Main array will be cleared in later phase
    return [...state.failedFileNamesToEmail];
  },
  [GettersTypes.GET_TENANT_LIST]: (state: StateType) => {
    return state.tenantList;
  },
  [GettersTypes.GET_TENANT_ID_LIST_LINK]: (state: StateType) => {
    return state.serviceDocs?.links.getTenantIdList ?? '';
  },
  [GettersTypes.GET_SELECTED_TENANT]: (state: StateType) => {
    return state.selectedTenant;
  },
  [GettersTypes.GET_METADATA_FROM_XMP_FLAG]: (state: StateType) => {
    return state.metadataFromXmpFlag;
  },
  [GettersTypes.GET_TENANT_USER_LIST]: (state: StateType) => {
    return state.tenantUserList;
  },
  [GettersTypes.GET_TENANT_USER_LIST_EMAIL_USER_ID_MAP]: (state: StateType) => {
    return state.tenantUserListEmailUserIdMap;
  },
  [GettersTypes.GET_TENANT_USER_LIST_USER_NAME_USER_ID_MAP]: (state: StateType) => {
    return state.tenantUserListUserNameUserIdMap;
  },
  [GettersTypes.GET_TENANT_USER_LIST_USER_ID_USER_EMAIL_MAP]: (state: StateType) => {
    return state.tenantUserListUserIdUserEmailMap;
  },
  [GettersTypes.GET_METADATA_HISTORY_FLAG]: (state: StateType) => {
    return state.metadataFromHistoryFlag;
  },
  [GettersTypes.GET_XMP_EMAIL]: (state: StateType) => {
    return state.xmpEmail;
  },
  [GettersTypes.GET_USER_SESSION]: (state: StateType) => {
    return state.userSession;
  },
  [GettersTypes.GET_TENANT_CONFIG_MAX_HISTORY]: (state: StateType) => {
    return state.tenantConfig?.maxHistoryCount || 10; // default 10;
  },
  [GettersTypes.GET_METADATA_RESET_FLAG]: (state: StateType) => {
    return state.metadataResetFlag;
  },
  [GettersTypes.GET_COMPLETE_UPLOAD_FLAGS]: (state: StateType) => {
    return state.completeUploadFlags;
  },
  [GettersTypes.GET_ACTIVITY_UPDATE_TIME_INTERVALS]: (state: StateType) => {
    return state.activityUpdateTimeIntervals;
  },
  [GettersTypes.GET_APP_BAR_SELECTED_INDEX]: (state: StateType) => {
    return state.appBarSelectedIndex;
  },
  [GettersTypes.GET_TENANT_GROUPS_INFO]: (state: StateType) => {
    return state.tenantGroupsInfo;
  },
  [GettersTypes.GET_GROUP_LIST_UPDATED_FLAG]: (state: StateType) => {
    return state.groupListUpdatedFlag;
  },
  [GettersTypes.GET_USER_MERGE_GROUPS_PERMISSION]: (state: StateType) => {
    return state.userMergeGroupsPermission;
  },
};

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
