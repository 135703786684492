






























































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { TenantConfig, UpdateTenantConfigPayload, CreateTenantConfigPayload } from '@qmu/common/dto/TenentConfigDtos';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import { validEmailRules, validEmailsRules, validPerSessionUploadCountRules } from '@/common/validatorRules';
import { updateTenantConfig, getTenantConfigForced, createUserDefineTenantConfig } from '@/common/tenantConfigHandler';
import { fetchSESIdentities, addNewIdentity } from '@/common/notificationServices';
import { SESIdentity } from '@qmu/common/dto/notificationModels';
import { getFileExtension, sleep } from '@qmu/common/util/general';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import { post, put } from '@/common/rest';
import store, { ActionsTypes, GettersTypes } from '@/store';
import { isUserNameValid } from '@qmu/common/util/nameValidators';

interface IdentityItem {
  email: string;
  status: string | undefined;
  disabled: boolean;
  color: string;
}

@Component
export default class UploadSetting extends Vue {
  @Prop({ default: false }) isCreateTenant!: boolean;
  @Prop({ default: false }) onFormSubmit!: boolean;
  @Watch('onFormSubmit')
  async onFormSubmitDataChange() {
    this.createTenantConfig();
  }
  tenantList: Array<string> = [];
  selectedTenantConfig: TenantConfig | null = null;
  emailForNotification: string | null = null;
  maxHistoryCount: string | null = null;
  maxParallelUploadCount: string | null = null;
  maxUploadCountPerSession: string | null = null;
  maxFileSize: string | null = null;
  customerLogo: File | null = null;
  customerLogoUri = '';
  tenantDataLoaded = false;
  disableFields = false;
  updating = false;
  emailValidator = validEmailRules;
  emailsValidator = validEmailsRules;
  perSessionUploadCountValidator = validPerSessionUploadCountRules;
  mediaUploadFileTypes: Array<string> = [];
  mediaUploadFileTypesLabel = 'Supported Upload File Type';

  additionalUploadFileTypes: Array<string> = [];
  additionalUploadFileTypesLabel = 'Supported Additional Upload File Type';
  additionalEmailAddresses: Array<string> = [];
  additionalEmailAddressesLabel = 'Additional Email Addresses';
  adminInfoEmailAddresses: Array<string> = [];
  adminInfoEmailAddressesLabel = 'Admin Info Email Addresses';
  SESIddEmailAddresses: Array<IdentityItem> = [];
  currentFromAddress = '';
  fetchingTenantData = false;
  addNewEmailFormValid = false;
  loadingAddNewEmail = false;
  newEmail = '';
  loadingVerifiedEmails = false;
  logoHovered = false;
  tenantListRules = [(v: string) => !!v || 'Tenant is required'];
  newTenantName = '';
  createTenantNameLabel = 'create new tenant';

  isLargeScreen() {
    return this.$vuetify.breakpoint.smAndUp;
  }

  getSelectedTenantLogo() {
    if (this.customerLogo) return URL.createObjectURL(this.customerLogo);
    return this.selectedTenantConfig?.tenantLogo ?? '';
  }

  assignedTargetTenantLogo(event: Event) {
    const files = (event.target as HTMLInputElement).files as File[] | null;
    if (files && files.length) this.customerLogo = files[0];
  }

  openTenantLogoSelectionWindow() {
    (this.$refs.tenantLogoSelectionWindowRef as HTMLElement | undefined)?.click();
  }

  async addNewSenderEmail() {
    if (!this.addNewEmailFormValid) return;
    this.loadingAddNewEmail = true;
    try {
      const response = await addNewIdentity(this.newEmail);
      if (response.status !== 200) EventBus.$emit(EVENTS.SHOW_TOAST, "Can't add new email, please try again later!", TOAST.ERROR);
      else EventBus.$emit(EVENTS.SHOW_TOAST, 'Email added, please check your inbox and click the verification link!', TOAST.SUCCESS);
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Something went wrong while adding new email', TOAST.ERROR);
    } finally {
      this.loadingAddNewEmail = false;
    }
  }

  get selectedTenant() {
    return store.getters[GettersTypes.GET_SELECTED_TENANT];
  }

  set selectedTenant(value: string | null) {
    store.dispatch(ActionsTypes.SET_SELECTED_TENANAT, value);
  }

  @Watch('selectedTenant')
  async onDataChanged() {
    await this.getGroupUserList();
  }

  async created() {
    this.tenantList = store.getters[GettersTypes.GET_TENANT_LIST];
    await this.getGroupUserList();
  }

  identitiesObjectToArray(identitiesObject: SESIdentity): Array<IdentityItem> {
    return Object.keys(identitiesObject).map(email => {
      return {
        email,
        status: identitiesObject[email].VerificationStatus,
        disabled: identitiesObject[email].VerificationStatus === 'Pending',
        color: identitiesObject[email].VerificationStatus === 'Pending' ? 'primary' : 'success',
      };
    });
  }

  async reFetchSESIdentities() {
    this.loadingVerifiedEmails = true;
    try {
      const sesIdentitiesResponse = await fetchSESIdentities(this.selectedTenant);
      if (sesIdentitiesResponse.status !== 200) return EventBus.$emit(EVENTS.SHOW_TOAST, "Can't fetch verified email ids!", TOAST.ERROR);
      this.SESIddEmailAddresses = this.identitiesObjectToArray(sesIdentitiesResponse.data.data.identities);
      this.currentFromAddress = sesIdentitiesResponse.data.data.currentFromAddress;
      this.emailForNotification = this.currentFromAddress;
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'SES identities fetching failed', TOAST.ERROR);
    }
    this.loadingVerifiedEmails = false;
  }

  async getGroupUserList() {
    if (this.selectedTenant) {
      this.tenantDataLoaded = false;
      this.fetchingTenantData = true;
      if (!this.isCreateTenant) {
        this.selectedTenantConfig = await getTenantConfigForced(this.selectedTenant);
        this.mediaUploadFileTypes = this.selectedTenantConfig.fileExtensions.mediaFileExtensions;
        this.additionalUploadFileTypes = this.selectedTenantConfig.fileExtensions.additionalFileExtensions;
        this.additionalEmailAddresses = this.selectedTenantConfig.additionalEmails;
        this.adminInfoEmailAddresses = this.selectedTenantConfig.adminInfoEmails;
        this.emailForNotification = this.selectedTenantConfig.emailForNotification;
        this.maxHistoryCount = this.selectedTenantConfig.maxHistoryCount.toString();
        this.maxParallelUploadCount = this.selectedTenantConfig.maxParallelUplaodCount.toString();
        this.maxUploadCountPerSession = this.selectedTenantConfig.maxUploadCountPerSession;
        this.maxFileSize = this.selectedTenantConfig.maximumFileSize.toString();
        this.customerLogoUri = this.selectedTenantConfig.tenantLogo;
        await this.reFetchSESIdentities();
      }
    }
    this.tenantDataLoaded = true;
    this.fetchingTenantData = false;
  }

  clearUserList() {
    this.selectedTenant = null;
    this.tenantDataLoaded = false;
  }

  async logoS3SingleUpload(signedUrl: string, file: File, waitBeforeExecutionInMillis = 500) {
    let tryNumber = 1;
    while (tryNumber) {
      try {
        await put(signedUrl, { data: file });
        break;
      } catch (error) {
        if (error.response && error.response.status >= 500) {
          throw error;
        }
        waitBeforeExecutionInMillis = tryNumber > 220 ? 60 * 1000 : tryNumber > 110 ? 30 * 1000 : waitBeforeExecutionInMillis * 2;
        tryNumber++;
        await sleep(waitBeforeExecutionInMillis);
      }
    }
  }

  async uploadCustomerLogo(tenantId: string, customerLogo: File): Promise<boolean> {
    const tenantLogoEndpoint = (store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks).tenantLogo;
    if (!tenantLogoEndpoint) return false;
    try {
      const resp = await post(tenantLogoEndpoint, { params: { tenantId } });
      if (!resp.data?.data?.url) return false;
      await this.logoS3SingleUpload(resp.data.data.url, customerLogo);
      return true;
    } catch (error) {
      return false;
    }
  }

  async updateSystemSetting() {
    if (!(this.$refs.mediaUploadComboboxRef as Vue & { validate: () => boolean }).validate()) return;
    this.disableFields = true;
    this.updating = true;
    if (
      !this.selectedTenant ||
      !this.mediaUploadFileTypesLabel ||
      !this.additionalUploadFileTypes ||
      !this.emailForNotification ||
      !this.maxHistoryCount ||
      !this.maxParallelUploadCount ||
      !this.maxUploadCountPerSession ||
      !this.maxFileSize ||
      !this.additionalEmailAddresses ||
      !this.adminInfoEmailAddresses
    )
      return;
    try {
      const payload: UpdateTenantConfigPayload = {
        tenantId: this.selectedTenant,
        tenantConfig: {
          fileExtensions: { mediaFileExtensions: this.mediaUploadFileTypes, additionalFileExtensions: this.additionalUploadFileTypes },
          emailForNotification: this.emailForNotification,
          maxHistoryCount: parseInt(this.maxHistoryCount, 10),
          maxParallelUplaodCount: parseInt(this.maxParallelUploadCount, 10),
          maxUploadCountPerSession: this.maxUploadCountPerSession,
          maximumFileSize: parseInt(this.maxFileSize, 10),
          additionalEmails: this.additionalEmailAddresses,
          adminInfoEmails: this.adminInfoEmailAddresses,
        },
      };

      if (!this.customerLogo) {
        if (await updateTenantConfig(payload)) EventBus.$emit(EVENTS.SHOW_TOAST, 'Tenant Config updated', TOAST.SUCCESS);
        else EventBus.$emit(EVENTS.SHOW_TOAST, 'Tenant Config update failed', TOAST.WARNING);
      } else if (['png'].includes(getFileExtension(this.customerLogo.name).toLowerCase())) {
        const [logoStatus, configStatus] = await Promise.all([this.uploadCustomerLogo(this.selectedTenant, this.customerLogo), updateTenantConfig(payload)]);
        if (logoStatus && configStatus) EventBus.$emit(EVENTS.SHOW_TOAST, 'Tenant Config and logo updated', TOAST.SUCCESS);
        else EventBus.$emit(EVENTS.SHOW_TOAST, 'Something went wrong', TOAST.WARNING);
      } else {
        EventBus.$emit(EVENTS.SHOW_TOAST, 'Please select a PNG file for logo', TOAST.WARNING);
      }
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, `Failed to update tenant config for: ${this.selectedTenant}`, TOAST.ERROR);
    }
    this.disableFields = false;
    this.updating = false;
  }

  removeFromSelectedMediaUploadFileTypes(item: string) {
    this.mediaUploadFileTypes.splice(this.mediaUploadFileTypes.indexOf(item), 1);
  }

  removeFromSelectedAdditionalUploadFileTypes(item: string) {
    this.additionalUploadFileTypes.splice(this.additionalUploadFileTypes.indexOf(item), 1);
  }

  removeFromSelectedAdditionalEmailAddresses(item: string) {
    this.additionalEmailAddresses.splice(this.additionalEmailAddresses.indexOf(item), 1);
  }

  removeFromSelectedAdminInfoEmailAddresses(item: string) {
    this.adminInfoEmailAddresses.splice(this.adminInfoEmailAddresses.indexOf(item), 1);
  }

  async createTenantConfig() {
    if (!(this.$refs.mediaUploadComboboxRef as Vue & { validate: () => boolean }).validate()) return;
    if (
      !this.selectedTenant ||
      !this.mediaUploadFileTypesLabel ||
      !this.additionalUploadFileTypes ||
      !this.emailForNotification ||
      !this.maxHistoryCount ||
      !this.maxParallelUploadCount ||
      !this.maxUploadCountPerSession ||
      !this.maxFileSize ||
      !this.additionalEmailAddresses ||
      !this.adminInfoEmailAddresses
    )
      return;

    if (this.tenantList.map(id => id.toLowerCase()).includes(this.newTenantName.toLowerCase())) {
      EventBus.$emit(EVENTS.SHOW_TOAST, `${this.newTenantName} already exist`, TOAST.ERROR);
      return;
    }
    if (!isUserNameValid(this.newTenantName)) {
      EventBus.$emit(EVENTS.SHOW_TOAST, `tenant name must be lower letter or number or both`, TOAST.ERROR);
      return;
    }

    try {
      const { userPoolId } = store.getters[GettersTypes.GET_CLIENT_CONFIGS];
      const payload: CreateTenantConfigPayload = {
        tenantId: this.newTenantName,
        userPoolId,
        tenantConfig: {
          fileExtensions: { mediaFileExtensions: this.mediaUploadFileTypes, additionalFileExtensions: this.additionalUploadFileTypes },
          emailForNotification: this.emailForNotification,
          maxHistoryCount: parseInt(this.maxHistoryCount, 10),
          maxParallelUplaodCount: parseInt(this.maxParallelUploadCount, 10),
          maxUploadCountPerSession: this.maxUploadCountPerSession,
          maximumFileSize: parseInt(this.maxFileSize, 10),
          additionalEmails: this.additionalEmailAddresses,
          adminInfoEmails: this.adminInfoEmailAddresses,
          tenantLogo: '',
        },
      };
      if (!(await createUserDefineTenantConfig(payload))) {
        EventBus.$emit(EVENTS.SHOW_TOAST, `Please try again to create tenant config for: ${this.newTenantName}`, TOAST.INFO);
        return;
      }
      store.dispatch(ActionsTypes.SET_TENANT_LIST, [...this.tenantList, this.newTenantName]);
      this.tenantList = store.getters[GettersTypes.GET_TENANT_LIST];
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Tenant Config Created', TOAST.SUCCESS);
    } catch (error) {
      if (error.response?.status === 409) {
        EventBus.$emit(EVENTS.SHOW_TOAST, error.response.data?.error?.message ?? 'Something wrong', TOAST.INFO);
        return;
      }
      EventBus.$emit(EVENTS.SHOW_TOAST, `Failed to create tenant config for: ${this.newTenantName}`, TOAST.ERROR);
    }
  }

  inviteUsers() {
    this.$dialog.showInviteUsersDialog();
  }
}
