import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';

export enum Method {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

type MethodType = {
  [key in Method]?: string;
};

export const functionNameMap: Record<keyof ServiceLinks, MethodType> = {
  item: { POST: 'create file upload ID' },
  itemUpload: { GET: 'fetch file upload ID', POST: 'start file upload' },
  itemUploadPartsSignUrls: { POST: 'make system ready file upload' },
  itemMultipartUploadComplete: { POST: 'complete file upload' },
  items: { POST: 'create file upload ID' },
  metadataOnS3: { GET: 'fetch metadata from server', POST: 'upload metadata on server' },
  mdfFile: { GET: 'fetch metadata file' },
  mdfsInfo: { GET: 'fetch metadata info' },
  updateMappings: { POST: 'update metadata mapping' },
  createMappings: { POST: 'create metadata mapping' },
  mapping: { GET: 'metadata mapping ' },
  mdfsAndMappingsLink: { POST: 'fetch metadata mapping' },
  itemHistory: { POST: 'fetch user`s upload activity' },
  toggleSubscription: { POST: 'update email subscription status' },
  getUserActivity: { POST: 'fetch user`s system activity' },
  getSubscriptionInfo: { GET: 'fetch email subscription status' },
  getUsersOfGroup: { POST: 'fetch user list from its group' },
  getGroupsOfUser: { POST: 'fetch an user groups information' },
  assignGroupToUser: { POST: 'update user group' },
  removeGroupFromUser: { POST: 'remove an user group' },
  createEmailTemplate: { POST: 'create email template' },
  sendEmail: { POST: 'send email to user' },
  getSESIdentities: { GET: 'fetch sender email address list' },
  addNewIdentity: { POST: 'add new sender email address' },
  changeSenderEmail: { POST: 'update sender email address' },
  getUserList: { POST: 'fetch user list' },
  getSpecificUser: { GET: 'fetch an user info' },
  updateSpecificUser: { POST: 'update an user info' },
  createMetadata: { POST: 'upload metadata' },
  updateMetadata: { POST: 'update metadata progress' },
  metadataHistory: { POST: 'fetch metadata history' },
  itemTrackers: { POST: 'update file progress' },
  getMdfIds: { POST: 'fetch metadata ID' },
  tenantConfig: { GET: 'fetch tenant config', POST: 'update tenant config' },
  getTenantIdList: { GET: 'fetch tenant list' },
  tenantLogo: { GET: 'fetch tenant logo', POST: 'upload tenant logo' },
  deleteUserAccount: { POST: 'delete user account' },
  tenant: { POST: 'create tenant' },
  tenantGroup: {
    POST: 'create tenant group',
    GET: 'get tenant group',
    PATCH: 'update tenant group',
    DELETE: 'delete tenant group',
  },
  tenantGroups: { GET: 'get tenant groups', PATCH: 'update tenant group' },
  userPermission: { GET: 'get user permission schema' },
  inviteUsers: { POST: 'invite tenant users' },
};
