import { cloneDeep } from 'lodash';
import { BasePermission, BasePermissionV2 } from '../dto/userGroupsDto';

export const basePermission: BasePermission = {
  item: {
    allowUpload: false,
  },
  metadataHistory: {
    readHistory: false,
    readFile: false,
  },
  metadataMapping: {
    create: false,
    read: false,
    update: false,
  },
  email: {
    addNewSender: false,
    readSenders: false,
    changeCurrentSender: false,
    createTemplate: false,
    sendEmail: false,
    readSubscriptionStatus: false,
    writeSubscriptionStatus: false,
  },
  tenantConfig: {
    create: false,
    update: false,
    read: false,
  },
  activity: {
    readSystemActivity: false,
    readUserActivity: false,
  },
  users: {
    readUsers: false,
    deleteUsers: false,
    assignGroups: false,
    removeGroups: false,
    readGroups: false,
    readUsersOfGroups: false,
  },
  groups: {
    createNewGroupAndPermission: false,
    deleteGroupAndPermission: false,
    updateGroupname: false,
    updateGroupPermission: false,
    readGroupPermission: false,
  },
};

export const basePermissionV2: BasePermissionV2 = {
  'item/allowUpload': false,
  'metadataHistory/readHistory': false,
  'metadataHistory/readFile': false,
  'metadataMapping/create': false,
  'metadataMapping/read': false,
  'metadataMapping/update': false,
  'email/addNewSender': false,
  'email/readSenders': false,
  'email/changeCurrentSender': false,
  'email/createTemplate': false,
  'email/sendEmail': false,
  'email/readSubscriptionStatus': false,
  'email/writeSubscriptionStatus': false,
  'tenantConfig/create': false,
  'tenantConfig/update': false,
  'tenantConfig/read': false,
  'activity/readSystemActivity': false,
  'activity/readUserActivity': false,
  'users/readUsers': false,
  'users/updateUsers': false,
  'users/deleteUsers': false,
  'users/assignGroups': false,
  'users/removeGroups': false,
  'users/readGroups': false,
  'users/readUsersOfGroups': false,
  'groups/createNewGroupAndPermission': false,
  'groups/deleteGroupAndPermission': false,
  'groups/updateGroupname': false,
  'groups/updateGroupPermission': false,
  'groups/readGroupPermission': false,
};

export const getCompatableGroupPermission = (groupPermission: Partial<BasePermission>, altBasePermission: BasePermission = basePermission, priorityValue: boolean = true): BasePermission => {
  return {
    activity: {
      readSystemActivity: groupPermission.activity?.readSystemActivity === priorityValue ? groupPermission.activity?.readSystemActivity : altBasePermission.activity.readSystemActivity,
      readUserActivity: groupPermission.activity?.readUserActivity === priorityValue ? groupPermission.activity?.readUserActivity : altBasePermission.activity.readUserActivity,
    },
    email: {
      addNewSender: groupPermission.email?.addNewSender === priorityValue ? groupPermission.email?.addNewSender : altBasePermission.email.addNewSender,
      changeCurrentSender: groupPermission.email?.changeCurrentSender === priorityValue ? groupPermission.email?.changeCurrentSender : altBasePermission.email.changeCurrentSender,
      createTemplate: groupPermission.email?.createTemplate === priorityValue ? groupPermission.email?.createTemplate : altBasePermission.email.createTemplate,
      readSenders: groupPermission.email?.readSenders === priorityValue ? groupPermission.email?.readSenders : altBasePermission.email.readSenders,
      sendEmail: groupPermission.email?.sendEmail === priorityValue ? groupPermission.email?.sendEmail : altBasePermission.email.sendEmail,
      readSubscriptionStatus: groupPermission.email?.readSubscriptionStatus === priorityValue ? groupPermission.email?.readSubscriptionStatus : altBasePermission.email.readSubscriptionStatus,
      writeSubscriptionStatus: groupPermission.email?.writeSubscriptionStatus === priorityValue ? groupPermission.email?.writeSubscriptionStatus : altBasePermission.email.writeSubscriptionStatus,
    },
    groups: {
      createNewGroupAndPermission: groupPermission.groups?.createNewGroupAndPermission === priorityValue ? groupPermission.groups?.createNewGroupAndPermission : altBasePermission.groups.createNewGroupAndPermission,
      deleteGroupAndPermission: groupPermission.groups?.deleteGroupAndPermission === priorityValue ? groupPermission.groups?.deleteGroupAndPermission : altBasePermission.groups.deleteGroupAndPermission,
      readGroupPermission: groupPermission.groups?.readGroupPermission === priorityValue ? groupPermission.groups?.readGroupPermission : altBasePermission.groups.readGroupPermission,
      updateGroupname: groupPermission.groups?.updateGroupname === priorityValue ? groupPermission.groups?.updateGroupname : altBasePermission.groups.updateGroupname,
      updateGroupPermission: groupPermission.groups?.updateGroupPermission === priorityValue ? groupPermission.groups?.updateGroupPermission : altBasePermission.groups.updateGroupPermission,
    },
    item: {
      allowUpload: groupPermission.item?.allowUpload === priorityValue ? groupPermission.item?.allowUpload : altBasePermission.item.allowUpload,
    },
    metadataHistory: {
      readFile: groupPermission.metadataHistory?.readFile === priorityValue ? groupPermission.metadataHistory?.readFile : altBasePermission.metadataHistory.readFile,
      readHistory: groupPermission.metadataHistory?.readHistory === priorityValue ? groupPermission.metadataHistory?.readHistory : altBasePermission.metadataHistory.readHistory,
    },
    metadataMapping: {
      create: groupPermission.metadataMapping?.create === priorityValue ? groupPermission.metadataMapping?.create : altBasePermission.metadataMapping.create,
      read: groupPermission.metadataMapping?.read === priorityValue ? groupPermission.metadataMapping?.read : altBasePermission.metadataMapping.read,
      update: groupPermission.metadataMapping?.update === priorityValue ? groupPermission.metadataMapping?.update : altBasePermission.metadataMapping.update,
    },
    tenantConfig: {
      create: groupPermission.tenantConfig?.create === priorityValue ? groupPermission.tenantConfig?.create : altBasePermission.tenantConfig.create,
      read: groupPermission.tenantConfig?.read === priorityValue ? groupPermission.tenantConfig?.read : altBasePermission.tenantConfig.read,
      update: groupPermission.tenantConfig?.update === priorityValue ? groupPermission.tenantConfig?.update : altBasePermission.tenantConfig.update,
    },
    users: {
      assignGroups: groupPermission.users?.assignGroups === priorityValue ? groupPermission.users?.assignGroups : altBasePermission.users.assignGroups,
      deleteUsers: groupPermission.users?.deleteUsers === priorityValue ? groupPermission.users?.deleteUsers : altBasePermission.users.deleteUsers,
      readGroups: groupPermission.users?.readGroups === priorityValue ? groupPermission.users?.readGroups : altBasePermission.users.readGroups,
      readUsers: groupPermission.users?.readUsers === priorityValue ? groupPermission.users?.readUsers : altBasePermission.users.readUsers,
      readUsersOfGroups: groupPermission.users?.readUsersOfGroups === priorityValue ? groupPermission.users?.readUsersOfGroups : altBasePermission.users.readUsersOfGroups,
      removeGroups: groupPermission.users?.removeGroups === priorityValue ? groupPermission.users?.removeGroups : altBasePermission.users.removeGroups,
    },
  };
};

export const getMergedPermission = (permissions: BasePermission[], priorityValue = true) => {
  if (permissions.length === 0) return null;
  let recentPermission: BasePermission = cloneDeep(permissions[0]);
  for (let i = 1; i < permissions.length; i++) {
    recentPermission = getCompatableGroupPermission(recentPermission, permissions[i], priorityValue);
  }
  return recentPermission;
};

const permissionV2 = cloneDeep(basePermissionV2);

export const getMergeWithBasePermissionV2 = (groupPermission: BasePermissionV2, BasePermissionV2: BasePermissionV2 = permissionV2) => {
  const basePermissionKeys = Object.keys(BasePermissionV2);
  basePermissionKeys.forEach(key => {
    BasePermissionV2[key] = groupPermission[key] === true || groupPermission[key] === false ? groupPermission[key] : BasePermissionV2[key] === true;
  });
  return cloneDeep(BasePermissionV2);
};

export const getCompatibleGroupPermissionV2 = (permissionSelectedKey: Array<string>, BasePermissionV2: BasePermissionV2 = permissionV2) => {
  permissionSelectedKey.forEach(key => (BasePermissionV2[key] = true));
  return BasePermissionV2;
};

const getMergeTwoPermission = (permission1: BasePermissionV2, permission2: BasePermissionV2) => {
  const permissionKeys = Object.keys(permission1);
  permissionKeys.forEach(key => (permission1[key] = permission1[key] === true ? permission1[key] : permission2[key] === true));
  return permission1;
};

export const getMergedPermissionAllInOne = (permissions: BasePermissionV2[]) => {
  const len = permissions.length;
  let recentPermission: BasePermissionV2;
  if (len === 0) return null;
  recentPermission = cloneDeep(permissions[0]);
  for (let i = 1; i < len; i++) {
    recentPermission = getMergeTwoPermission(recentPermission, permissions[i]);
  }
  return recentPermission;
};
