


























































































import { Component, Vue } from 'vue-property-decorator';
import UploadFile from '@/components/UploadFile.vue';
import { find } from 'lodash';
import { FileStatus, FileUpload } from '@/utils/Types/UploadTypes';
import MetadataComponent from '@/components/MetadataComponent.vue';
import NotificationSubscription from '@/components/NotificationSubscription.vue';
import store, { ActionsTypes } from '@/store';
import { GettersTypes } from '@/store';
import eventBus, { EVENTS, TOAST } from '@/eventBus';
import { getFileExtension } from '@qmu/common/util/general';
import { UploadFromType } from '@qmu/common/enum/uploadType';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import { TenantConfig } from '@qmu/common/dto/TenentConfigDtos';
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';
import { checkSession, updateUserSession } from '@/common/userSession';
import ErrorSession from '@/components/ErrorSession.vue';
import XmpUploadComponent from '@/components/XmpUploadComponent.vue';
import { MimirMetadataDto } from '@qmu/common/dto/MetadataModels';

@Component({
  components: {
    UploadFile,
    MetadataComponent,
    NotificationSubscription,
    Splitpanes,
    Pane,
    ErrorSession,
    XmpUploadComponent,
  },
})
export default class Upload extends Vue {
  jsonFileContent: string | null = null;
  metadataAddFlag: boolean = false;
  isDragging = false;
  resetFlag = false;
  session = true;
  sessionLoaderFlag = false;
  jsonFlag: boolean = false;

  getJsonifyFlag(jsonifyMappingFlag: boolean) {
    this.jsonFlag = jsonifyMappingFlag;
  }

  async beforeMount() {
    if (!(await checkSession())) this.session = false;
  }

  isLargeScreen() {
    return this.$vuetify.breakpoint.smAndUp;
  }

  get metadataFieldStatus() {
    return store.getters[GettersTypes.GET_METADATA_FIELD_STATUS];
  }

  /**
   * Checks if the given fileName extension is an upload supported file types present in the store for given uploadFromType
   * If store has supported type '*' then all types are allowed
   * If file has not extension and allowed file types has a '' empty type then file without extensions are also allowed
   * @param fileName
   * @param uploadFromType
   * @returns true if file extention is supported file type else false
   */
  isFileSupported(fileName: string, uploadFromType: UploadFromType): boolean {
    const supportedTypes = this.getSupportedFileTypes(uploadFromType);
    const resp = supportedTypes.includes(getFileExtension(fileName).toLowerCase());
    return resp ? true : supportedTypes.includes('*');
  }

  /**
   * Split list of files into allowed and notAllowed list for specific file type
   * @param files
   * @param uploadFromType
   * @returns allowed and notAllowed list of files as object {allowed,notAllowed}
   */
  splitSupportedUnsupportedFiles(files: FileUpload[], uploadFromType: UploadFromType): { allowed: FileUpload[]; notAllowed: FileUpload[] } {
    const allowed: FileUpload[] = [],
      notAllowed: FileUpload[] = [];
    for (const file of files) this.isFileSupported(file.name, uploadFromType) ? allowed.push(file) : notAllowed.push(file);
    return { allowed, notAllowed };
  }

  /**
   * Get supported file type for different upload file selector
   * @param uploadFromType - for which enum type you want to get the supported upload types
   * @return Array<string>
   */
  getSupportedFileTypes(uploadFromType: UploadFromType) {
    switch (uploadFromType) {
      case UploadFromType.DRAG_AND_DROP:
        return (store.getters[GettersTypes.GET_TENANT_CONFIG] as TenantConfig).fileExtensions.mediaFileExtensions;
      case UploadFromType.UPLOAD_MEDIA:
        return (store.getters[GettersTypes.GET_TENANT_CONFIG] as TenantConfig).fileExtensions.mediaFileExtensions;
      case UploadFromType.UPLOAD_ADDITIONAL_FILE:
        return (store.getters[GettersTypes.GET_TENANT_CONFIG] as TenantConfig).fileExtensions.additionalFileExtensions;
      default:
        return [];
    }
  }

  /**
   * Show warning msg to the user about the unsupported files with information
   * about the supported file types for given uploadFromType
   * @param unsupportedFiles
   * @param uploadFromType
   * @return void
   */
  showUnsupportedFilesWarning(unsupportedFiles: FileUpload[], uploadFromType: UploadFromType): void {
    let names = '';
    for (const unsupportedFile of unsupportedFiles) names += `<li> ${unsupportedFile.name} </li>`;
    const baseHtml = `The following file types are currently permitted for upload by the administrator:<br>${this.getSupportedFileTypes(uploadFromType).join(', ')}<br>The following file(s) can therefore not be uploaded:<br>${names}`;
    this.$dialog.showSimpleDialog({
      closeOnActionClick: true,
      icon: 'mdi-alert',
      maxWidth: 500,
      header: 'Warning!',
      baseHtml,
      infoOnly: true,
    });
  }

  /**
   * Receives all files and only starts uploading allowed files only
   * And shows warning with instruction if any notAllowed Files were provided
   * @param files
   * @param uploadFromType
   * @returns void
   */
  async proceedSupportedUploadOnly(files: FileUpload[], uploadFromType: UploadFromType): Promise<void> {
    const { allowed, notAllowed } = this.splitSupportedUnsupportedFiles(files, uploadFromType);
    await this.addFilesInUploadQueue(allowed);
    if (notAllowed.length) this.showUnsupportedFilesWarning(notAllowed, uploadFromType);
  }

  get specificUserLink() {
    return (store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks).updateSpecificUser;
  }

  get maximumFileUploadAtATime() {
    return (store.getters[GettersTypes.GET_TENANT_CONFIG] as TenantConfig).maxParallelUplaodCount;
  }

  get maximumFileSize() {
    return (store.getters[GettersTypes.GET_TENANT_CONFIG] as TenantConfig).maximumFileSize;
  }

  get maximumUploadCountInCurrentSession() {
    return (store.getters[GettersTypes.GET_TENANT_CONFIG] as TenantConfig).maxUploadCountPerSession;
  }

  get startFlag() {
    return store.getters[GettersTypes.GET_UPLOAD_START];
  }

  set startFlag(value: boolean) {
    store.dispatch(ActionsTypes.UPDATE_UPLOAD_START, value);
  }

  get fileUploadQueue(): FileUpload[] {
    return store.getters[GettersTypes.GET_FILES];
  }

  get allFileStatus(): FileStatus {
    return store.getters[GettersTypes.GET_ALL_FILE_STATUS];
  }

  hasQuataInCurrentSessionToUpload(count: number) {
    try {
      if (this.maximumUploadCountInCurrentSession === '*') return true;
      return parseInt(this.maximumUploadCountInCurrentSession) >= this.fileUploadQueue.length + count;
    } catch (error) {
      return false;
    }
  }

  clickUploadMediaFileInput() {
    (this.$refs.selectMediaFile as HTMLElement | undefined)?.click();
  }

  getMetadataAddFlag(metadataAddFlag: boolean) {
    this.metadataAddFlag = metadataAddFlag;
  }

  showCurrentSessionUploadQuataWarning(failedFiles: FileUpload[]) {
    let baseHtml = `Maximum upload per session is ${this.maximumUploadCountInCurrentSession}. ${
      this.fileUploadQueue.length === 0 ? `You tried to upload ${failedFiles.length} files.` : `Currently you are uploading ${this.fileUploadQueue.length} files.`
    }<br>The following file(s) can therefore not be uploaded:<br>`;
    for (const file of failedFiles) {
      baseHtml = `${baseHtml}<li>${file.name}</li>`;
    }
    this.$dialog.showSimpleDialog({
      closeOnActionClick: true,
      icon: 'mdi-alert',
      maxWidth: 500,
      header: 'Warning!',
      baseHtml,
      infoOnly: true,
    });
  }

  /**
   * Triggerd when user clicks 'upload media' button
   * It ensures that nested function call will filter out the unsupported files for 'upload media'
   * @param event - receives automatic event on change
   */
  uploadFromManuallySelectedMediaFiles(event: Event) {
    const files = (event.target as HTMLInputElement).files as FileUpload[] | null;
    if (!files || !files.length) return;
    if (this.hasQuataInCurrentSessionToUpload(files.length)) this.proceedSupportedUploadOnly(files, UploadFromType.UPLOAD_MEDIA);
    else this.showCurrentSessionUploadQuataWarning(files);
  }

  showMaximumUploadSizeWarning(failedFiles: File[]) {
    let baseHtml = `Files larger than ${this.maximumFileSize} GB are currently not permitted by the administrator.<br>The following file(s) can therefore not be uploaded:<br>`;
    for (const file of failedFiles) {
      baseHtml = `${baseHtml}<li>${file.name}</li>`;
    }
    this.$dialog.showSimpleDialog({
      closeOnActionClick: true,
      icon: 'mdi-alert',
      maxWidth: 500,
      header: 'Warning!',
      baseHtml,
      infoOnly: true,
    });
  }
  /**
   * Triggerd when user drag and drop files in UI
   * It ensures that nested function call will filter out the unsupported files for 'drag and drop'
   * @param event - receives automatic event on change
   */
  uploadFromDropedFiles({ dataTransfer }: DragEventInit) {
    this.isDragging = false;
    const bigFiles = [];
    const uploadReady: Array<FileUpload> = [];
    for (const file of dataTransfer!.files) {
      if (file.size / 1024 / 1024 / 1024 >= this.maximumFileSize) bigFiles.push(file);
      else uploadReady.push(file as FileUpload);
    }
    if (bigFiles.length) this.showMaximumUploadSizeWarning(bigFiles);
    else if (this.hasQuataInCurrentSessionToUpload(uploadReady.length)) this.proceedSupportedUploadOnly(uploadReady, UploadFromType.DRAG_AND_DROP);
    else this.showCurrentSessionUploadQuataWarning(uploadReady);
  }

  async addFilesInUploadQueue(newFileList: FileUpload[]) {
    try {
      this.sessionLoaderFlag = true;
      if (!(await checkSession())) {
        this.session = false;
        return;
      }
      await updateUserSession(this.specificUserLink, { session: true });
      this.sessionLoaderFlag = false;
      let currentlyUploading = 0;

      // Push in upload Queue
      for (const file of newFileList) {
        const alreadyInQueue = find(this.fileUploadQueue, { name: file.name });
        if (!alreadyInQueue) {
          this.fileUploadQueue.push(file);
          this.$set(this.allFileStatus, file.name, 'queued');
        }
      }

      for (const file of this.fileUploadQueue) {
        if (this.allFileStatus[file.name] === 'processing') currentlyUploading++;
        else if (this.allFileStatus[file.name] === 'queued' && currentlyUploading < this.maximumFileUploadAtATime) {
          this.$set(this.allFileStatus, file.name, 'processing');
          currentlyUploading++;
        } else if (!this.allFileStatus[file.name] && currentlyUploading < this.maximumFileUploadAtATime) {
          this.$set(this.allFileStatus, file.name, 'processing');
          currentlyUploading++;
        }
      }
    } catch (err) {
      eventBus.$emit(EVENTS.SHOW_TOAST, 'Please refresh the brower and try again.', TOAST.WARNING);
    }
  }

  getJsonFormData(jsonFormData: MimirMetadataDto) {
    this.jsonFileContent = JSON.stringify(jsonFormData);
  }

  allowDropFile(event: DragEvent) {
    event.stopPropagation();
    if (event.dataTransfer) event.dataTransfer.dropEffect = 'copy';
  }

  resetMetadataTriggered(resetTriggered: boolean) {
    this.jsonFileContent = null;
  }
}
