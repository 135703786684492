export enum UserType {
  SUPER_ADMIN = 'super-admin',
  TENANT_ADMIN = 'tenant-admin',
  NORMAL_USER = 'normal-user',
  READONLY_USER = 'readonly-user',
}

// TODO : Will be removed on phase 2, as its going to be a multitenant system
export const allAvailableGroups = (): string[] => [UserType.SUPER_ADMIN, UserType.NORMAL_USER, UserType.TENANT_ADMIN];

export enum CognitoCustomAttributeType {
  TENANT_ID = 'custom:tenantId',
  GROUPS_ID = 'custom:groupsGuid',
  IS_TENANT_ADMIN = 'custom:isTenantAdmin',
}
