





































import { updateUserSession } from '@/common/userSession';
import { getDescriptionAttributes, getdescriptionsChildValues } from '@/common/xmpUtils';
import eventBus, { EVENTS, TOAST } from '@/eventBus';
import store, { ActionsTypes, GettersTypes } from '@/store';
import { abortAllFiles } from '@/utils/fileUtilities';
import { getEmailFromXMP } from '@/utils/metadataUtils';
import { MetadataType, MimirMdfCacheDto } from '@qmu/common/dto/MetadataModels';
import { GetMdfFileFieldsSchema, MappingDataSchema } from '@qmu/common/dto/mimirDto';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import { isEmpty } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import xmljs from 'xml-js';

@Component
export default class XmpUploadComponent extends Vue {
  fileXmp: File | null = null;
  fileContent: string = '';
  jsonMapFlag = false;

  get startFlag() {
    return store.getters[GettersTypes.GET_UPLOAD_START];
  }

  set startFlag(value: boolean) {
    store.dispatch(ActionsTypes.UPDATE_UPLOAD_START, value);
  }

  get specificUserLink() {
    return (store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks).updateSpecificUser;
  }

  get metadataXmpFlag() {
    return store.getters[GettersTypes.GET_METADATA_FROM_XMP_FLAG];
  }

  set metadataXmpFlag(value: boolean) {
    store.dispatch(ActionsTypes.SET_METADATA_XMP_FLAG, value);
  }

  get mappingData() {
    return (store.getters[GettersTypes.GET_MIMIR_MDF_CACHE] as MimirMdfCacheDto).mappingData;
  }

  set mappingData(value: MappingDataSchema) {
    store.dispatch(ActionsTypes.SET_MIMIR_CACHE_MAPPING_DATA, value);
  }

  get mdf() {
    return (store.getters[GettersTypes.GET_MIMIR_MDF_CACHE] as MimirMdfCacheDto).mdf;
  }

  set mdf(value: GetMdfFileFieldsSchema[]) {
    store.dispatch(ActionsTypes.SET_MIMIR_CACHE_MDF, value);
  }

  get isFetchingMdfMap() {
    return isEmpty(this.mdf);
  }

  get dataObject() {
    return store.getters[GettersTypes.GET_METADATA_FIELD_VALUES];
  }

  get xmpEmail() {
    return store.getters[GettersTypes.GET_XMP_EMAIL];
  }

  set xmpEmail(value: string | null) {
    store.dispatch(ActionsTypes.SET_XMP_EMAIL, value);
  }

  get metadataFieldStatus() {
    return store.getters[GettersTypes.GET_METADATA_FIELD_STATUS];
  }

  set metadataFieldStatus(value: boolean) {
    store.dispatch(ActionsTypes.UPDATE_METADATA_FIELD_STATUS, value);
  }

  get resetFlag() {
    return store.getters[GettersTypes.GET_METADATA_RESET_FLAG];
  }

  set resetFlag(value: boolean) {
    store.dispatch(ActionsTypes.SET_METADATA_RESET_FLAG);
  }

  getFieldNameById(id: string) {
    for (const item of this.mdf) {
      if (item.id === id) return item.fieldId;
    }
  }

  jsonifyMappings() {
    this.jsonMapFlag = !this.jsonMapFlag;
    this.$emit('jsonifyMappingFlag', this.jsonMapFlag);
  }

  private storeMappings(jsonObject: MetadataType) {
    const keys = Object.keys(jsonObject);
    const data: MetadataType = {};
    for (const key of keys) {
      data[key] = jsonObject[key];
    }

    store.dispatch(ActionsTypes.SET_METADATA_FIELDS, data);
  }

  reset() {
    this.fileXmp = null;
    store.dispatch(ActionsTypes.RESET_METADATA_FIELD_VALUES, {});
    this.metadataFieldStatus = false;
    // forms get reset when we change the key
    // here reset flag is used as a key
    this.resetFlag = !this.resetFlag;
    this.$emit('resetMetadata', true);
  }

  async clearSession() {
    if (!this.startFlag) {
      eventBus.$emit(EVENTS.SHOW_TOAST, 'Nothing to upload in this session', TOAST.WARNING);
      return;
    }
    this.$dialog.showSimpleDialog(
      {
        closeOnActionClick: true,
        icon: 'mdi-alert',
        maxWidth: 400,
        header: 'Warning!',
        baseHtml: 'All recent activity and session will be cleared.',
      },
      async () => {
        await updateUserSession(this.specificUserLink, { session: false });
        abortAllFiles();
        store.dispatch(ActionsTypes.DELETE_UPLOAD_SESSION);
        this.resetFlag = !this.resetFlag;
        this.fileXmp = null;
        store.dispatch(ActionsTypes.RESET_METADATA_FIELD_VALUES, {});
        this.$emit('resetMetadata', true);
      }
    );
  }

  async readXMP() {
    if (this.fileXmp !== null && (this.fileXmp.name.includes('.XMP') || this.fileXmp.name.includes('.xmp'))) {
      const reader = new FileReader();
      reader.readAsText(this.fileXmp!);
      this.metadataXmpFlag = true;
      reader.onload = async () => {
        this.fileContent = reader.result!.toString();
        await this.getMappings();
        this.resetFlag = !this.resetFlag;
      };
      setTimeout(() => {
        this.metadataXmpFlag = false; // setTimeout is important to trigger store to change flag
      }, 2000);
    } else eventBus.$emit(EVENTS.SHOW_TOAST, 'XMP file is not valid', TOAST.ERROR);
  }

  async getMappings() {
    let jsonData = JSON.parse(xmljs.xml2json(this.fileContent, { compact: true, spaces: 4 }));
    const jsonObject: MetadataType = {};
    if (this.mappingData) {
      const keys = Object.keys(this.mappingData!);
      for (let key of keys) {
        const literal = this.mappingData[key] ? this.mappingData[key].xmpFieldName : '';
        if (literal && (literal.includes('photoshop:') || literal.includes('photomechanic:') || literal.includes('Iptc4xmpCore:'))) {
          jsonObject[this.getFieldNameById(key)!] = getDescriptionAttributes(literal, jsonData['x:xmpmeta']['RDF']['Description']['_attributes']);
        } else if (literal && (literal.includes('dc:') || literal.includes('GettyImagesGIFT:'))) {
          jsonObject[this.getFieldNameById(key)!] = getdescriptionsChildValues(literal, jsonData['x:xmpmeta']['RDF']['Description']);
        }
      }
    }
    this.storeMappings(jsonObject);
    this.xmpEmail = getEmailFromXMP(jsonData);
  }
}
