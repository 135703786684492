
























































import store, { ActionsTypes, GettersTypes } from '@/store';
import { Component, Vue, Watch } from 'vue-property-decorator';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import BasePermissionForm from './BasePermissionForm.vue';
import { MultiTenantGroupsInfo, TenantGroupsInfo } from '@/utils/Types/TenantGroupsInfoType';
import { deleteTenantGroup } from '@/common/tenantGroups';
@Component({
  components: {
    BasePermissionForm,
  },
})
export default class GroupPermissionManagement extends Vue {
  selectedGroupGUID: string | null = null;
  selectedTenant: string = store.getters[GettersTypes.GET_SELECTED_TENANT];
  readonlyPermission: boolean = true;
  tenantGroupsInfo: TenantGroupsInfo = (store.getters[GettersTypes.GET_TENANT_GROUPS_INFO] as MultiTenantGroupsInfo)[this.selectedTenant];

  @Watch('selectedTenant')
  updateTenantGroupStore() {
    const tenantGroupInfo = (store.getters[GettersTypes.GET_TENANT_GROUPS_INFO] as MultiTenantGroupsInfo)[this.selectedTenant];
    if (!tenantGroupInfo) store.dispatch(ActionsTypes.SET_TENANT_GROUPS_INFO, { tenantId: this.selectedTenant, groupInfo: {} });
    this.selectedTenantGroupInfo = (store.getters[GettersTypes.GET_TENANT_GROUPS_INFO] as MultiTenantGroupsInfo)[this.selectedTenant];
  }

  get groupInfoUpdated() {
    return store.getters[GettersTypes.GET_GROUP_LIST_UPDATED_FLAG] as boolean;
  }

  @Watch('groupInfoUpdated')
  updateSelectedTenantGroupInfo() {
    this.selectedTenantGroupInfo = (store.getters[GettersTypes.GET_TENANT_GROUPS_INFO] as MultiTenantGroupsInfo)[this.selectedTenant];
  }

  get selectedTenantGroupInfo() {
    this.groupInfoUpdated; // important for reactivity as we are updating UI when groupInfoUpdated
    return this.tenantGroupsInfo;
  }

  set selectedTenantGroupInfo(payload: TenantGroupsInfo) {
    this.tenantGroupsInfo = payload;
  }

  radioButtonChanged(_: string) {
    this.readonlyPermission = true;
  }

  editGroupPermission(groupGUID: string) {
    if (groupGUID === this.selectedGroupGUID) {
      this.readonlyPermission = !this.readonlyPermission;
    }
  }

  addGroup() {
    if (this.selectedTenant) this.$dialog.showAddGroupDialog(this.selectedTenant);
    else EventBus.$emit(EVENTS.SHOW_TOAST, 'Target tenant not found. Please refresh the broser and try again', TOAST.INFO);
  }

  deleteGroupConfirmation(groupGUID: string, groupName: string) {
    this.$dialog.showSimpleAsyncDialog(
      {
        icon: 'mdi-alert',
        maxWidth: 400,
        header: 'Warning!',
        baseHtml: `Delete group: ${groupName} ?`,
      },
      async () => this.deleteGroup(groupGUID)
    );
  }

  async deleteGroup(groupGUID: string) {
    try {
      this.$dialog.showPleaseWaitDialog('Deleting user group in progress');
      await deleteTenantGroup(this.selectedTenant, groupGUID);
      store.dispatch(ActionsTypes.REMOVE_TENANT_GROUPS_INFO, { tenantId: this.selectedTenant, groupGUID });
      EventBus.$emit(EVENTS.SHOW_TOAST, 'tenant group deleted', TOAST.SUCCESS);
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'failed to delete group', TOAST.ERROR);
    } finally {
      this.$dialog.hidePleaseWaitDialog();
    }
  }
}
