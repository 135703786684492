const emailValidationRegx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/;

export const validEmailRules = [(v: string) => !!v || 'Email is required', (v: string) => !v || emailValidationRegx.test(v) || 'Email must be valid'];
export const validEmailsRules = [(v: string[]) => !v || isEmailsValid(v) || 'Email must be valid'];
export const validPerSessionUploadCountRules = [
  (v: string) => {
    if (v === '*') return true;
    try {
      const value = parseInt(v);
      return (value >= 1 && value <= 100) || 'Value exceeded';
    } catch (error) {
      return false;
    }
  },
];
const isEmailsValid = (emails: string[]) => !emails.some(item => emailValidationRegx.test(item) === false);

const passwordValidationRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
export const validPasswordRules = [(v: string) => !!v || 'Password is required', (v: string) => !v || passwordValidationRegx.test(v) || 'Min. 8 characters with at least one capital letter, a number and a special character.'];
