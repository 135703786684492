
























import { Component, Vue } from 'vue-property-decorator';
import SystemSetting from './SystemSetting.vue';
import EventBus from '@/eventBus';
@Component({
  components: { SystemSetting },
})
export default class CreateTenant extends Vue {
  dialog = false;
  submitForm = false;
}
