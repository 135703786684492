import eventBus, { EVENTS, TOAST } from '@/eventBus';
import store, { ActionsTypes, GettersTypes } from '@/store';
import { MimirMdfCacheDto } from '@qmu/common/dto/MetadataModels';
import { GetMdfFileFieldsSchema, GetMdfsAndMappingsSchema } from '@qmu/common/dto/mimirDto';
import { isEmpty } from 'lodash';
import * as metadataHandler from '@/common/metadataHandler';
import { getUpPageInitMdfTypes } from '@qmu/common/metaDataSetting/mimir';

export type MdfType = {
  id: string;
  fieldId: string;
  required: boolean;
  type: string;
  alternatives: Array<{ value: string; label: string }>;
  defaultValue: Array<string> | string;
  freeform: boolean;
};

export type Mapping = {
  id: string;
  value: Array<string> | string;
};

export const getDescriptionAttributes = (literal: string, json: { [key: string]: Record<string, any> }) => {
  if (literal === 'photoshop:City') return json[literal] ? [json[literal]] : [];
  else if (literal === 'photoshop:State' || (literal === 'Iptc4xmpCore:CountryCode')) return [json[literal]];
  return json[literal];
};

export const getdescriptionsChildValues = (literal: string, json: { [key: string]: Record<string, any> }) => {
  switch (literal) {
    case 'dc:creator':
      return json[literal] ? liToList(json[literal]['Seq']) : '';
    case 'dc:description':
      return json[literal]['Alt'].li['_text'];
    case 'dc:subject':
      return json[literal] ? liToList(json[literal]['Bag']) : '';
    case 'GettyImagesGIFT:RoutingDestinations':
      return json[literal]['Bag'].li['_text'];
    default:
      return '';
  }
};

export const liToList = (list: { [key: string]: Record<string, any> }) => {
  let keys = Object.keys(list.li);
  let array: Array<string> = [];

  if (keys.length === 1) {
    array.push(list.li['_text']);
    return array;
  }

  keys.forEach(key => {
    array.push(list.li[key]['_text']);
  });
  return array;
};

export const fetchMimirData = async () => {
  const mimirData = store.getters[GettersTypes.GET_MIMIR_MDF_CACHE] as MimirMdfCacheDto;
  if (isEmpty(mimirData.mdf) || isEmpty(mimirData.mappingData) || isEmpty(mimirData.mdfItems) || isEmpty(mimirData.mdfUpload) || isEmpty(mimirData.mdfIdRoh)) {
    try {
      const mdfAndMap = await fetchRequiredMetadata();
      const mdfIds = await metadataHandler.fetchMdfId(getUpPageInitMdfTypes());
      const mimirCache: MimirMdfCacheDto = {
        mdf: mdfAndMap.mdfsFields,
        mappingData: mdfAndMap.mdfsMappings,
        mdfItems: mdfAndMap.mdfItems,
        mdfUpload: mdfAndMap.mdfUpload,
        mdfIdRoh: mdfIds.roh,
      };

      store.dispatch(ActionsTypes.SET_MIMIR_CACHE_MDF, mimirCache.mdf);
      store.dispatch(ActionsTypes.SET_MIMIR_CACHE_MAPPING_DATA, mimirCache.mappingData);
      store.dispatch(ActionsTypes.SET_MIMIR_CACHE_MDF_ITEMS, mimirCache.mdfItems);
      store.dispatch(ActionsTypes.SET_MIMIR_CACHE_MDF_UPLOADS, mimirCache.mdfUpload);
      store.dispatch(ActionsTypes.SET_MIMIR_CACHE_MDF_ID_ROH, mimirCache.mdfIdRoh);
    } catch (error) {
      eventBus.$emit(EVENTS.SHOW_TOAST, 'Something went wrong in Mimir', TOAST.ERROR);
    }
  }
};

const fetchRequiredMetadata = async (): Promise<GetMdfsAndMappingsSchema> => {
  try {
    return await metadataHandler.fetchMdfsAndMapping(getUpPageInitMdfTypes());
  } catch (error) {
    eventBus.$emit(EVENTS.SHOW_TOAST, 'Failed to load Metadata', TOAST.ERROR);
    return { mdfsFields: [], mdfsMappings: {}, mdfItems: [], mdfUpload: [] };
  }
};
