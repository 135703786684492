














import { validEmailsRules } from '@/common/validatorRules';
import store, { GettersTypes } from '@/store';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { UserPoolDataModel, UserPoolData } from '@/common/userPoolData';
import EventBus, { EVENTS, TOAST } from '@/eventBus';

@Component
export default class InviteUsers extends Vue {
  @Prop({ default: false }) public applyClicked!: boolean;

  userEmailAddressesLabel = 'User Email Addresses';
  userEmailAddresses: Array<string> = [];
  emailsValidator = validEmailsRules;
  userPoolData: UserPoolDataModel = new UserPoolData();
  loading = false;

  removeFromSelectedUserEmailAddresses(item: string) {
    this.userEmailAddresses.splice(this.userEmailAddresses.indexOf(item), 1);
  }

  @Watch('applyClicked')
  async inviteUsersEmail() {
    if (!(this.$refs.usersEmailComboboxRef as Vue & { validate: () => boolean }).validate()) return;
    const tenantId = store.getters[GettersTypes.GET_TENANT_ID];
    try {
      this.loading = true;
      const res = await this.userPoolData.inviteUsers(tenantId, this.userEmailAddresses);
      this.$dialog.hideInviteUsersDialog();
      if (res.failedInvitation.length || res.existUser.length) {
        this.$dialog.showSimpleDialog({
          closeOnActionClick: true,
          icon: 'mdi-delete-alert-outline',
          maxWidth: 400,
          header: `Invite Users Status`,
          baseHtml: `Failed to send invitation users: ${res.failedInvitation.length ? res.failedInvitation.join(', ') : 0}. </br> Users already exist: ${res.existUser.length ? res.existUser.join(', ') : 0}`,
        });
      } else EventBus.$emit(EVENTS.SHOW_TOAST, 'Invite users successfully', TOAST.SUCCESS);
    } catch (error) {
      this.$dialog.hideInviteUsersDialog();
      EventBus.$emit(EVENTS.SHOW_TOAST, `Failed to send users invitation`, TOAST.ERROR);
    } finally {
      this.loading = false;
    }
  }
}
