

















































































import { Component, Vue } from 'vue-property-decorator';
import { Auth } from 'aws-amplify';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import { bootStrapper } from '@/bootstrapClient';
import { validEmailRules, validPasswordRules } from '@/common/validatorRules';
import LoginPageLoader from './LoginPageLoader.vue';

enum AuthSignals {
  LOGGED_IN = 'LOGGED_IN',
  REQUIRE_MFA = 'REQUIRE_MFA',
}

@Component({components:{
  LoginPageLoader
}})
class Login extends Vue {
  email: string = '';
  password: string = '';
  mfaCode: string = '';
  isMfaNeeded: boolean = false;
  showPassword: boolean = false;
  isTryLogin: boolean = false;
  emailRules = validEmailRules;
  passwordRules = [(v: string) => !!v || 'Password is required'];
  isNewPasswordNeeded = false;
  newPassword = '';
  user: any = null
  passRules = validPasswordRules

  /**
   * Parent will use this emit signal to update UI
   */
  async loggedIn() {
    await bootStrapper();
    this.$emit('onLoggedIn', true);
  }

  /**
   * Emit signal to parent on successful login
   */
  async tryLogin(): Promise<AuthSignals | string> {
    try {
      this.user=await Auth.signIn(this.email, this.password);
      if(this.user.challengeName === 'NEW_PASSWORD_REQUIRED') await this.completeNewPassword()
      await Auth.currentAuthenticatedUser();
      await this.loggedIn();
      return AuthSignals.LOGGED_IN;
    } catch (error) {
      if (error.message == 'User is not confirmed.') {
        return AuthSignals.REQUIRE_MFA;
      }
      return error.message;
    }
  }

  /**
   * Called when user clicks login button.
   * 1. Redirect user on successful login
   * 2. Handle verification procedure when MFA is Required
   */
  async emailLogin(): Promise<void> {
    if (!(this.$refs.loginForm as Vue & { validate: () => boolean }).validate()) return;
    this.isTryLogin = true;
    const signInTry = await this.tryLogin();
    if (signInTry === AuthSignals.REQUIRE_MFA) {
      this.$dialog.showSimpleDialog(
        {
          closeOnActionClick: true,
          icon: 'mdi-stop',
          maxWidth: 400,
          header: 'Verification required!',
          baseHtml: 'Email verification required? Receive Verification Token?',
        },
        this.verifyMfa,
        undefined,
        this.cancelMfa
      );
    } else if (signInTry == AuthSignals.LOGGED_IN) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Successfully logged in', TOAST.SUCCESS);
    } else {
      EventBus.$emit(EVENTS.SHOW_TOAST, signInTry, TOAST.ERROR);
    }
    this.isTryLogin = false;
  }

  /**
   * DPA ID LOGIN
   */
  async dpaIdLogin(): Promise<void> {
    await Auth.federatedSignIn({ customProvider: 'DpaId' });
  }

  /**
   * Gets called when user wants to input verification code from UI Conditional Rendering
   */
  async verifyMfa() {
    try {
      await Auth.resendSignUp(this.email);
      this.isMfaNeeded = true;
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'VERIFICATION CODE SEND FAILED', TOAST.ERROR);
    }
  }

  cancelMfa() {
    EventBus.$emit(EVENTS.SHOW_TOAST, 'CANT LOGIN WITHOUT VERIFICATION', TOAST.ERROR);
  }

  /**
   * Called when user clicks Confirm from verificaion code input field.
   * It verifies the user with MFA Code, if successful redirects the user to specific page
   * Else skip with warning.
   */
  async confirmMfa() {
    if (!this.mfaCode) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'VERIFICATION CODE REQUIRED', TOAST.INFO);
      return;
    }
    try {
      await Auth.confirmSignUp(this.email, this.mfaCode);
      const signInTry = await this.tryLogin();
      if (signInTry == AuthSignals.LOGGED_IN) {
        EventBus.$emit(EVENTS.SHOW_TOAST, 'Successfully logged in', TOAST.SUCCESS);
      } else {
        EventBus.$emit(EVENTS.SHOW_TOAST, signInTry, TOAST.ERROR);
      }
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'INVALID VERIFICATION KEY', TOAST.ERROR);
    }
  }

  async completeNewPassword(){
    this.isNewPasswordNeeded = true;
  }

  async confirmNewPassword(){
    if (!(this.$refs.newPasswordForm as Vue & { validate: () => boolean }).validate()) return;
    this.isTryLogin = true;
    this.password= this.newPassword;
    try{
      await Auth.completeNewPassword(this.user,this.newPassword)
      const signInTry = await this.tryLogin();

      if (signInTry == AuthSignals.LOGGED_IN) {
        EventBus.$emit(EVENTS.SHOW_TOAST, 'Successfully logged in', TOAST.SUCCESS);
      } else {
        EventBus.$emit(EVENTS.SHOW_TOAST, signInTry, TOAST.ERROR);
      }
    }catch(error){
      EventBus.$emit(EVENTS.SHOW_TOAST, 'New Password not Created', TOAST.ERROR);
    }
    this.isTryLogin = true;
  }
}
export default Login;
