






























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  getBasePermissionTreeViewObject,
  getGroupPermissionObject,
  getBasePermission,
  getGroupPermission,
  getUserSelectedPermissionId,
  updateGroup,
  getBasePermissionV2,
  getBasePermissionTreeViewObjectV2,
  getUserSelectedPermissionIdV2,
  updateGroupV2,
} from '@/common/basePermission';
import { BasePermission, BasePermissionV2 } from '@qmu/common/dto/userGroupsDto';
import { ParentNode } from '@/utils/Types/TreeviewObjectType';
import { cloneDeep } from 'lodash';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import store, { GettersTypes } from '@/store';
import { MultiTenantGroupsInfo } from '@/utils/Types/TenantGroupsInfoType';
import { isOldPermission } from '@qmu/common/util/isOldPermission';

@Component
export default class BasePermissionForm extends Vue {
  @Prop({ default: null }) public groupGUID!: string | null;
  @Prop({ default: true }) public readOnly!: boolean;
  basePermissionTreeViewObject: ParentNode[] = [];
  showSelectedPermission = true;
  preview = false;
  selectedPermission: string[] = [];
  basePermission: BasePermission | BasePermissionV2 | null = null;
  initialSelectedGroupPermission: string[] = [];
  readOnlyPermission = this.readOnly;
  selectedTenant = store.getters[GettersTypes.GET_SELECTED_TENANT] as string;
  tenantGroups = (store.getters[GettersTypes.GET_TENANT_GROUPS_INFO] as MultiTenantGroupsInfo)[this.selectedTenant];
  loading = false;
  isOldPer = false;

  get groupName() {
    return this.tenantGroups[this.groupGUID!].groupName;
  }

  @Watch('readOnly')
  changeReadOnlyStatus() {
    this.readOnlyPermission = this.readOnly;
  }

  async created() {
    await this.getGroupPermission();
  }

  async cancelPermissionUpdate() {
    this.selectedPermission = this.initialSelectedGroupPermission;
    this.readOnlyPermission = true;
    this.preview = false;
    this.showSelectedPermission = true;
  }

  @Watch('groupGUID')
  async getGroupPermission() {
    if (this.groupGUID === null) return;
    this.loading = true;
    this.showSelectedPermission = false;
    try {
      const permission = await getGroupPermission(this.groupGUID);
      this.isOldPer = isOldPermission(permission);
      let groupPermission;

      if (this.isOldPer) {
        this.basePermission = await getBasePermission();
        this.basePermissionTreeViewObject = getBasePermissionTreeViewObject(this.basePermission);
        groupPermission = getUserSelectedPermissionId(permission as BasePermission);
        this.initialSelectedGroupPermission = cloneDeep(groupPermission);
        this.selectedPermission = cloneDeep(groupPermission);
      } else {
        this.basePermission = await getBasePermissionV2();
        this.basePermissionTreeViewObject = getBasePermissionTreeViewObjectV2(this.basePermission);
        groupPermission = getUserSelectedPermissionIdV2(permission as BasePermissionV2);
        this.initialSelectedGroupPermission = cloneDeep(groupPermission);
        this.selectedPermission = cloneDeep(groupPermission);
      }
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Permission loading failed. Please try again later', TOAST.ERROR);
    }
    this.loading = false;
    this.showSelectedPermission = true;
  }

  async isConfirm() {
    if (this.basePermission === null || !this.groupGUID) return;
    if (this.preview) {
      this.loading = true;
      let updateableGroupPermissionSchema;
      if (this.isOldPer) updateableGroupPermissionSchema = getGroupPermissionObject(this.basePermission as BasePermission, this.selectedPermission);
      // TODO: transfer this object to DB for further operation with loading UI.
      try {
        if (this.isOldPer) await updateGroup(this.groupGUID, undefined, undefined, updateableGroupPermissionSchema);
        else updateGroupV2(this.groupGUID, undefined, undefined, this.selectedPermission);
        EventBus.$emit(EVENTS.SHOW_TOAST, 'Group permission updated', TOAST.SUCCESS);
        this.readOnlyPermission = true;
      } catch (error) {
        EventBus.$emit(EVENTS.SHOW_TOAST, 'Failed to update permission. Please try again later', TOAST.ERROR);
      }
      this.loading = false;
    }
    this.showSelectedPermission = !this.showSelectedPermission;
    this.preview = !this.preview;
  }

  addMore() {
    this.readOnlyPermission = false;
    this.showSelectedPermission = !this.showSelectedPermission;
    this.preview = !this.preview;
  }
}
