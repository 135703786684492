














import { updateUserSession } from '@/common/userSession';
import store, { GettersTypes } from '@/store';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ErrorSession extends Vue {
  get specificUserLink() {
    return (store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks).updateSpecificUser;
  }

  async clearSession() {
    this.$dialog.showSimpleDialog(
      {
        closeOnActionClick: true,
        icon: 'mdi-alert',
        maxWidth: 400,
        header: 'Warning!',
        baseHtml: 'All recent activity and session will be cleared.',
      },

      async () => {
        await updateUserSession(this.specificUserLink, { session: false });
        this.$router.go(0);
      }
    );
  }
}
