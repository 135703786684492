

































































import { SingleUserDataResponse } from '@qmu/common/dto/userDataDto';
import { BasicPayloadFileActivity } from '@qmu/common/dto/activityModels';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import EventBus, { EVENTS, TOAST } from '@/eventBus';
import { UserType, allAvailableGroups } from '@qmu/common/enum/UserTypes';
import store, { ActionsTypes, GettersTypes } from '@/store';
import { UserInfo } from '@qmu/common/dto/UserInfoDto';
import { get, post } from '@/common/rest';
import { ServiceLinks } from '@qmu/common/dto/ServiceDocumentDtos';
import { ItemState } from '@qmu/common/dto/itemModels';
import { ExclusiveStartKeyType } from '@qmu/common/dto/genericDto';
import { HandleLoadUserListPayload, UserListHandlerPayload } from '../dto/handleLoadActivityDto';
import UserList from './activityFields/UserList.vue';
import FilterBtn from './activityFields/FilterBtn.vue';
import ClearFilter from './activityFields/ClearFilter.vue';
import { getUserIdFromMappingData } from '@/utils/mappingUtilities';
import { isEmpty } from 'lodash';
import { UserOperationType } from '@/common/enums/userOperationEnums';
import { LoadUserEmitDto } from '../dto/loadUserDto';
import { MultiTenantGroupsInfo, TenantGroupsInfo } from '@/utils/Types/TenantGroupsInfoType';

@Component({
  components: {
    UserList,
    FilterBtn,
    ClearFilter,
  },
})
export default class LoadUser extends Vue {
  @Prop({ default: false }) showItemState!: boolean;
  @Prop({ default: false }) showAllUserActivity!: boolean;
  @Prop({ default: '' }) deletedUserName!: string;
  tenantList: string[] | null = null;
  selectedStatus = '';
  isUserListLoading = false;
  usersList: Array<SingleUserDataResponse> = [];
  itemStateList = Object.values(ItemState);
  tenantId = '';
  isEnd = true;
  pageNumber = 0;
  slOffset = 1;
  currentExclusiveStartKey: ExclusiveStartKeyType | null = null;
  lastExclusiveStartKey: ExclusiveStartKeyType | null = null;
  listExclusiveStartKey: ExclusiveStartKeyType[] = [];
  operationType = UserOperationType;
  isTriggerClear = false;
  filterSelectedUser = '';
  filterSelectedGroup = '';

  tenantListRules = [(v: string) => !!v || 'Tenant is required'];
  statusListRules = [(v: string) => !!v || 'Status is required'];

  changeSelected() {
    if (isEmpty(this.filterSelectedGroup)) this.filterSelectedGroup = '';
  }

  get AllGroups() {
    return allAvailableGroups().concat('None');
  }

  isUserDeletable(item: SingleUserDataResponse) {
    if ((store.getters[GettersTypes.GET_USER_ID] as string) === item.id) return true;
    if (this.userGroup === UserType.TENANT_ADMIN && item.groups?.includes(UserType.SUPER_ADMIN)) return true;
    return false;
  }

  getHeaders() {
    return [
      { text: 'Email', value: 'email', align: 'left' },
      { text: 'Groups', value: 'groups', align: 'left', sortable: false },
      { text: 'Edit group', value: 'groupEdit', align: 'center', sortable: false },
      ...(this.userGroup === this.userType.SUPER_ADMIN || this.userGroup === this.userType.TENANT_ADMIN ? [{ text: 'Delete user', value: 'userDelete', align: 'center', sortable: false }] : []),
    ];
  }
  get reactiveDeletedUser() {
    return this.deletedUserName;
  }

  set reactiveDeletedUser(value: string) {
    this.deletedUserName = value;
  }

  @Watch('deletedUserName')
  removedEntryOfUser() {
    if (!this.reactiveDeletedUser) return;
    const data = this.usersList.find(user => user.userName === this.reactiveDeletedUser);
    if (!data) return;
    const index = this.usersList.indexOf(data);
    if (index === -1) return;
    this.usersList.splice(index, 1);
    this.filterSelectedUser = '';
    this.filterSelectedGroup = '';
  }

  get users() {
    return this.usersList;
  }

  get triggerClear() {
    return this.isTriggerClear;
  }

  setSelected(selectedUser: string) {
    this.filterSelectedUser = selectedUser;
  }

  applyFiltering() {
    const selectedTenantId = store.getters[GettersTypes.GET_SELECTED_TENANT];
    const userId = getUserIdFromMappingData(selectedTenantId, this.filterSelectedUser);
    if (isEmpty(userId)) return this.getUserList();
    this.fetchUserInfo(selectedTenantId, userId);
  }

  async fetchUserInfo(tenantId: string, userId: string) {
    try {
      this.isUserListLoading = true;
      this.pageNumber = 1;
      this.isEnd = true;
      const url = (store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks).getSpecificUser as string;
      const params = {
        tenantId: tenantId,
        userId: userId,
      };
      const response = await get(url, { params });
      this.usersList = [response.data.data];
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Cannot load user info', TOAST.WARNING);
    }
    this.isUserListLoading = false;
  }

  clearFiltering() {
    this.isTriggerClear = !this.isTriggerClear;
    this.filterSelectedUser = '';
    this.filterSelectedGroup = '';
    this.applyFiltering();
  }

  filteredGroups(groups: string[]): string[] {
    return allAvailableGroups().filter(group => {
      return groups.includes(group);
    });
  }

  readableGroupNames(groups: string[]) {
    const tenantGroupInfo: TenantGroupsInfo = (store.getters[GettersTypes.GET_TENANT_GROUPS_INFO] as MultiTenantGroupsInfo)[this.selectedTenant!];
    return groups.map(item => tenantGroupInfo[item]?.groupName?? item) //TODO: later item return logic will be change
  }

  get selectedTenant() {
    return store.getters[GettersTypes.GET_SELECTED_TENANT];
  }

  set selectedTenant(value: string | null) {
    store.dispatch(ActionsTypes.SET_SELECTED_TENANAT, value);
  }

  @Watch('selectedTenant')
  async onDataChanged() {
    await this.getUserList();
  }

  async created() {
    this.tenantList = store.getters[GettersTypes.GET_TENANT_LIST];
    if (this.userGroup === this.userType.TENANT_ADMIN) this.tenantList = store.getters[GettersTypes.GET_TENANT_ID];
    this.itemStateList = Object.values(ItemState);
    this.isEnd = true;
    this.pageNumber = 0;
    this.slOffset = 1;
    this.listExclusiveStartKey = [];
    this.isUserListLoading = false;
    if (this.selectedTenant) this.tenantId = this.selectedTenant;
    await this.handleLoad({
      pageNumber: 1,
      isInitialLoad: false,
      isPrevious: false,
      isNext: false,
      selectedGroup: this.filterSelectedGroup,
      tenantId: this.tenantId,
    });
  }

  get defaultLimit() {
    return store.getters[GettersTypes.GET_TENANT_CONFIG_MAX_HISTORY];
  }

  get userType() {
    return UserType;
  }

  get userGroup() {
    return store.getters[GettersTypes.GET_USER_GROUP] as UserInfo;
  }

  async getUserList() {
    if (!this.selectedTenant) return;
    this.tenantId = this.selectedTenant;
    await this.handleLoad({
      pageNumber: 1,
      isInitialLoad: false,
      isPrevious: false,
      isNext: false,
      tenantId: this.tenantId,
      selectedGroup: this.filterSelectedGroup,
      limit: this.defaultLimit,
    });
  }

  async previousPage() {
    if (!this.currentExclusiveStartKey) return;
    const exclusiveStartKey = this.listExclusiveStartKey.pop();
    await this.handleLoad({
      pageNumber: this.pageNumber - 1,
      isInitialLoad: false,
      isPrevious: true,
      isNext: false,
      tenantId: this.tenantId,
      selectedGroup: this.filterSelectedGroup,
      limit: this.defaultLimit,
      exclusiveStartKey: exclusiveStartKey,
    });
  }

  async nextPage() {
    if (!this.currentExclusiveStartKey) return;
    await this.handleLoad({
      pageNumber: this.pageNumber + 1,
      isInitialLoad: false,
      isPrevious: false,
      isNext: true,
      tenantId: this.tenantId,
      selectedGroup: this.filterSelectedGroup,
      limit: this.defaultLimit,
      exclusiveStartKey: this.currentExclusiveStartKey,
    });
  }

  async handleLoad(parms: HandleLoadUserListPayload): Promise<void> {
    this.isUserListLoading = true;

    if (parms.isPrevious) parms.isNext = false;
    if (parms.isNext) parms.isPrevious = false;

    try {
      const response = await this.getUserListFromApi({
        tenantId: parms.tenantId,
        selectedGroup: this.filterSelectedGroup,
        limit: parms.limit,
        exclusiveStartKey: parms.exclusiveStartKey,
      });
      if (response.status !== 200) throw new Error('Cannot load user history.');
      this.usersList = response.data.data.results;

      if (!this.usersList?.length) {
        EventBus.$emit(EVENTS.SHOW_TOAST, 'Do not have any history.', TOAST.INFO);
        this.isEnd = true;
      }

      if (parms.isInitialLoad) {
        this.isEnd = true;
      } else {
        if (!response.data.data.exclusiveStartKey) {
          this.isEnd = true;
          if (parms.isNext) this.listExclusiveStartKey.push(this.lastExclusiveStartKey!);
        } else {
          this.isEnd = false;
          if (parms.isPrevious) this.lastExclusiveStartKey = parms.exclusiveStartKey!;
          else {
            this.listExclusiveStartKey.push(this.lastExclusiveStartKey!);
            this.lastExclusiveStartKey = this.currentExclusiveStartKey;
          }
          this.currentExclusiveStartKey = response.data.data.exclusiveStartKey;
        }
      }

      this.pageNumber = parms.pageNumber;
      if (this.pageNumber <= 1) this.lastExclusiveStartKey = null;
      if (parms.limit) {
        if (parms.isNext) this.slOffset += parms.limit;
        if (parms.isPrevious) this.slOffset -= parms.limit;
      }
    } catch (error) {
      EventBus.$emit(EVENTS.SHOW_TOAST, 'Cannot load user history', TOAST.WARNING);
    } finally {
      this.isUserListLoading = false;
    }
  }

  async getUserListFromApi(params: UserListHandlerPayload) {
    const serviceLinks = store.getters[GettersTypes.GET_SERVICE_LINKS] as ServiceLinks;
    const response = await post(serviceLinks.getUserList, { data: params });
    return response;
  }

  async getUserActivity(id: string) {
    if (!this.selectedTenant) return;
    const dataParams: BasicPayloadFileActivity = {
      userId: id,
      tenantId: this.selectedTenant,
      itemState: this.selectedStatus,
    };
    this.$emit('triggerUserInfo', dataParams);
  }

  async getUserInfo(user: SingleUserDataResponse, operationType: UserOperationType) {
    if (user.id) this.getUserActivity(user.id);
    const emitPayload: LoadUserEmitDto = { userData: user, tenantId: this.tenantId, operationType };
    this.$emit('userInfo', emitPayload);
  }

  getAllUserActivity() {
    const isTenantFormValidated = (this.$refs.tenantForm as Vue & { validate: () => boolean }).validate();
    const isStatusFormValidated = this.showItemState ? (this.$refs.statusForm as Vue & { validate: () => boolean }).validate() : false;
    if (!isTenantFormValidated && !isStatusFormValidated) return;
    if (!this.selectedTenant || (!this.selectedStatus && this.showItemState)) return;
    const dataParams: BasicPayloadFileActivity = {
      tenantId: this.selectedTenant,
      itemState: this.selectedStatus,
    };
    this.$emit('triggerAllUserActivity', dataParams);
  }

  clearUserList() {
    this.usersList = [];
    this.selectedTenant = null;
    this.isUserListLoading = false;
    EventBus.$emit(EVENTS.SHOW_TOAST, 'Clear Data!', TOAST.WARNING);
  }
}
