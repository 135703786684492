import { allAvailableGroups } from '../enum/UserTypes';

type Groups = Array<string>;
const allNormalGroup = allAvailableGroups();

export const separateGroupGuids = (groups: Groups) => {
  const guidGroups: Groups = [];
  const normalGroups: Groups = [];
  if (!isStringOfArray(groups)) return { guidGroups, normalGroups };

  groups.forEach(group => {
    if (allNormalGroup.includes(group)) normalGroups.push(group);
    else guidGroups.push(group);
  });

  return { guidGroups, normalGroups };
};

const isStringOfArray = (groups: Groups) => {
  return Array.isArray(groups) && groups.length > 0 && groups.every(group => typeof group === 'string');
};
